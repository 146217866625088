import React from 'react';
import imageObject from '../../../utils/images';

import '../style.css';

const LoginLogo = props => {
  return (
    <div className='circle-container center-item'>
      <img src={imageObject.folder} width='91' height='91' />
      <h5 className='mt-1 matt'>File Manager</h5>
    </div>
  );
};

export default LoginLogo;
