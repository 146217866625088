import React from 'react';
import { Container } from 'react-bootstrap';

import './style.css';
import imageObject from '../../utils/images';

const HeroImage = props => {
  const { title, image } = props;
  return (
    <React.Fragment>
      <div className='hero-image' style={{
        backgroundImage: `linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0.1),
    rgba(0, 0, 0, 0.0)
  ),
  url(${image == null ? imageObject.heroImageDefault : image})`
  }}>
        <Container>
          <div className="hero-title">
            <h1>{title.toUpperCase()}</h1>
          </div>
        </Container>
      </div>
      <div className="gradient-line"></div>
    </React.Fragment>
  );
};

/*
background-image: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0.2),
    rgba(0, 0, 0, 0.7)
  ),
  url('https://images.unsplash.com/photo-1494412651409-8963ce7935a7?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1350&q=80')

*/

export default HeroImage;
