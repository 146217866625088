import React, { useEffect } from 'react';
import './style.css';
import HeroImage from '../../components/HeroImage';
import { withRouter } from 'react-router-dom';
import MyContext from '../../middleware/context';
import imageObject from '../../utils/images';
import { Container, Button, Jumbotron } from 'react-bootstrap';
import FilePicker from './components/FilePicker.js';
import commonObject from '../../utils/common.js';

const Portal = props => {
  let dataArray =
    localStorage.getItem('admin') == 0
      ? [
          {
            image: imageObject.stockCircle,
            text: 'Stock'
          },
          {
            image: imageObject.invoiceCircle,
            text: 'Invoice'
          },
          {
            image: imageObject.suratJalanCircle,
            text: 'Surat Jalan'
          }
        ]
      : [
          {
            image: imageObject.stockCircle,
            text: 'Stock'
          },
          {
            image: imageObject.invoiceCircle,
            text: 'Invoice'
          },
          {
            image: imageObject.suratJalanCircle,
            text: 'Surat Jalan'
          },
          {
            image: imageObject.userCircle,
            text: 'Users'
          }
        ];

  let routeArr =
    localStorage.getItem('admin') == 0
      ? ['stock', 'invoice', 'surat-jalan-portal']
      : ['stock', 'invoice', 'surat-jalan-portal', 'user'];

  return (
    <MyContext.Consumer>
      {context => {
        const { currentLanguage } = context.state;
        return (
          <div className='mt-5 mb-5'>
            <HeroImage title='File Manager' />
            <Container className='page-container'>
              <Jumbotron>
                <h1>{`${commonObject.getDayTimeString()} ${localStorage.getItem(
                  'username'
                )}`}</h1>
                <p>
                  {`This is a portal to check your documents online, if you have any issues 
                  or inquiries, feel free to contact us at +6281265651212 (WA/CALL)`}
                </p>
                <p>
                  <Button
                    onClick={() => {
                      localStorage.clear();
                      props.history.push('/login');
                    }}
                    variant='outline-primary'
                  >
                    Logout
                  </Button>
                </p>
              </Jumbotron>
              <div className='mb-5 menu-container'>
                <FilePicker
                  action={key => {
                    props.history.push(routeArr[key]);
                  }}
                  dataArray={dataArray}
                />
              </div>
            </Container>
          </div>
        );
      }}
    </MyContext.Consumer>
  );
};

export default withRouter(Portal);
