export default {
  common: {
    questionTitle: {
      EN: 'Frequently asked questions',
      ID: 'Pertanyaan yang sering diajukan'
    }
  },
  FF: {
    title: {
      EN: 'Freight Forwarder',
      ID: 'Freight Forwarder'
    },
    subtext: {
      EN:
        'An door-to-door delivery process for individual or corporate interest from supplier to any designated place. We are capable of shipping through air, sea, and land that fits your requirements.',
      ID:
        'Proses tuntas dalam pengiriman barang untuk individual maupun perusahaan dari suplier ke tempat tujuan. Kami menyediakan pilihan pengiriman melalui udara, laut, dan darat yang disesuaikan dengan kebutuhan Anda.'
    },
    benefitTitle: {
      EN: 'Benefits from our Freight Forwading',
      ID: 'Keuntungan dari Freight Forwarder kami'
    },
    benefitArr: {
      EN: [
        {
          heading: 'Air',
          text:
            'For speed-oriented interest, our LCL air service will ensure your goods arrive in time and good condition.'
        },
        {
          heading: 'Sea',
          text:
            'Our ocean freight service covers both less-contain- er-load (LCL) and full-contain- er-load (FCL).'
        },
        {
          heading: 'Land',
          text:
            'For small to medium loads, we are capable of delivering by land for in-city and intercity throughout Indonesia.'
        }
      ],
      ID: [
        {
          heading: 'Udara',
          text:
            'Kecepatan adalah hal penting. Layanan LCL udara kami siap mengirim barang Anda sampai tepat waktu dan dalam kondisi baik.'
        },
        {
          heading: 'Laut',
          text:
            'Pengiriman laut kami mencakup less-container-load (LCL) dan full-container-load (FCL).'
        },
        {
          heading: 'Darat',
          text:
            'Untuk muatan kecil hingga medium, kami mampu mengirim melalui darat untuk cakupan dalam kota dan antarkota di seluruh Indonesia.'
        }
      ]
    },
    collapsibleContent: {
      EN: [
        {
          question: 'What value & benefits do we offer?',
          answer:
            'Customer satisfaction is always our priority. We Cut off your human resources cost, we have competent and credible staff in charge'
        },
        {
          question: 'How do I track the condition of my goods?',
          answer:
            'We have a transparent managerial system that makes the tracking process more visible for you and allows you to consult for better results.'
        },
        {
          question: 'Are my goods insured?',
          answer:
            'No, However we offer an optional insurance fee which ensures the safety of your goods and offering reimbursement for faulty carriage.'
        },
        {
          question: 'is shipping available for world wide?',
          answer: 'we cover most areas in asia'
        }
      ],
      ID: [
        {
          question: 'Apa keuntungan yang kami berikan?',
          answer:
            'Kepuasan pelanggan selalu menjadi prioritas kami. Kami Memotong biaya sumber daya manusia Anda, kami memiliki staf yang kompeten dan kredibel yang bertanggung jawab untuk anda'
        },
        {
          question: 'Bagaimana cara melacak kondisi barang saya?',
          answer:
            'Kami memiliki sistem manajerial transparan yang membuat proses pelacakan lebih terlihat untuk Anda dan memungkinkan Anda berkonsultasi untuk hasil yang lebih baik.'
        },
        {
          question: 'Apakah barang saya diasuransikan?',
          answer:
            'Tidak, Namun kami menawarkan biaya asuransi opsional yang menjamin keamanan barang Anda dan menawarkan penggantian untuk pengangkutan yang salah.'
        },
        {
          question: 'apakah pengiriman tersedia untuk seluruh dunia?',
          answer: 'kami mencakup semua wilayah di asia'
        }
      ]
    }
  },
  FS: {
    title: {
      EN: 'Fulfilment Storage',
      ID: 'Gudang Umum'
    },
    subtext: {
      EN:
        'ATS warehouse has a floor space of 2100 m2. Not only for storing, we are also capable of receiving and delivering the goods.',
      ID:
        'ATS memiliki gudang umum seluas 2100 m2. Selain penyimpanan, layanan kami dilengkapi dengan penerimaan dan pengiriman barang.'
    },
    benefitTitle: {
      EN: 'Benefits from our Fulfilment Storage',
      ID: 'Keuntungan dari Gudang Umum kami'
    },
    benefitArr: {
      EN: [
        {
          heading: 'Time-Saving',
          text:
            'Our dock fits up to 3 containers for loading and unloading at the same time.'
        },
        {
          heading: 'Quality Control',
          text:
            'Details in every process. We will make sure every process is as expected.'
        },
        {
          heading: 'Packing Service',
          text:
            'Ensuring your goods are received not only on time, but also in good condition.'
        },
        {
          heading: 'Medium Coverage',
          text:
            'Different shipping options based on your needs. We are capable of delivering through air, land, and sea.'
        }
      ],
      ID: [
        {
          heading: 'Hemat Waktu',
          text:
            'Dok kami memiliki kapasitas untuk 3 kontainer untuk melakukan pemasukan dan pengeluaran barang di saat bersamaan.'
        },
        {
          heading: 'Pengendalian Mutu',
          text:
            'Detail pada setiap proses. Kami memastikan semuanya berjalan sesuai ekspektasi.'
        },
        {
          heading: 'Layanan Pengemasan',
          text:
            'Memastikan barang Anda diterima tidak hanya tepat waktu, tapi juga dalam kondisi yang baik.'
        },
        {
          haeding: 'Cakupan Medan',
          text:
            'Berbagai pilihan untuk kebutuhan pengiriman Anda. Kami dapat mengirim melalui udara, darat, dan laut.'
        }
      ]
    },
    collapsibleContent: {
      EN: [
        {
          question: 'What value & benefits do we offer?',
          answer:
            'Customer satisfaction is always our priority. We Cut off your human resources cost, we have competent and credible staff in charge'
        },
        {
          question: 'How do I track the condition of my goods?',
          answer:
            'We have a transparent managerial system that makes the tracking process more visible for you and allows you to consult for better results.'
        },
        {
          question: 'Are my goods insured?',
          answer:
            'No, However we offer an optional insurance fee which ensures the safety of your goods and offering reimbursement for faulty carriage.'
        },
        {
          question: 'is shipping available for world wide',
          answer: 'we cover most areas in asia'
        }
      ],
      ID: [
        {
          question: 'Apa keuntungan yang kami berikan?',
          answer:
            'Kepuasan pelanggan selalu menjadi prioritas kami. Kami Memotong biaya sumber daya manusia Anda, kami memiliki staf yang kompeten dan kredibel yang bertanggung jawab untuk anda'
        },
        {
          question: 'Bagaimana cara melacak kondisi barang saya?',
          answer:
            'Kami memiliki sistem manajerial transparan yang membuat proses pelacakan lebih terlihat untuk Anda dan memungkinkan Anda berkonsultasi untuk hasil yang lebih baik.'
        },
        {
          question: 'Apakah barang saya diasuransikan?',
          answer:
            'Tidak, Namun kami menawarkan biaya asuransi opsional yang menjamin keamanan barang Anda dan menawarkan penggantian untuk pengangkutan yang salah.'
        },
        {
          question: 'apakah pengiriman tersedia untuk seluruh dunia?',
          answer: 'kami mencakup semua wilayah di asia'
        }
      ]
    }
  },
  CC: {
    title: {
      EN: 'Clearance Service',
      ID: 'Clearance Service'
    },
    subtext: {
      EN:
        'This service covers the operational process of custom duty that suits your business. We provide information and management about all documentation and cost that are needed to ensure the goods to be delivered as expected.',
      ID:
        'Layanan untuk mengatasi proses operasional bea cukai yang paling sesuai untuk mendukung bisnis Anda. Kami menyediakan informasi dan pengelolaan dokumen juga harga yang dibutuhkan untuk memastikan barang Anda sampai sesuai harapan.'
    },
    benefitTitle: {
      EN: 'Benefits from our Clearance Service',
      ID: 'Keuntungan dari Clearance Service kami'
    },
    benefitArr: {
      EN: [
        {
          heading: 'Air',
          text:
            'For speed-oriented interest, our LCL air service will ensure your goods arrive in time and good condition.'
        },
        {
          heading: 'Sea',
          text:
            'Our ocean freight service covers both less-contain- er-load (LCL) and full-contain- er-load (FCL).'
        },
        {
          heading: 'Land',
          text:
            'For small to medium loads, we are capable of delivering by land for in-city and intercity throughout Indonesia.'
        }
      ],
      ID: [
        {
          heading: 'Udara',
          text:
            'Kecepatan adalah hal penting. Layanan LCL udara kami siap mengirim barang Anda sampai tepat waktu dan dalam kondisi baik.'
        },
        {
          heading: 'Laut',
          text:
            'Pengiriman laut kami mencakup less-container-load (LCL) dan full-container-load (FCL).'
        },
        {
          heading: 'Darat',
          text:
            'Untuk muatan kecil hingga medium, kami mampu mengirim melalui darat untuk cakupan dalam kota dan antarkota di seluruh Indonesia.'
        }
      ]
    },
    collapsibleContent: {
      EN: [
        {
          question: 'What value & benefits do we offer?',
          answer:
            'Customer satisfaction is always our priority. We Cut off your human resources cost, we have competent and credible staff in charge'
        },
        {
          question: 'How do I track the condition of my goods?',
          answer:
            'We have a transparent managerial system that makes the tracking process more visible for you and allows you to consult for better results.'
        },
        {
          question: 'Are my goods insured?',
          answer:
            'No, However we offer an optional insurance fee which ensures the safety of your goods and offering reimbursement for faulty carriage.'
        },
        {
          question: 'is shipping available for world wide',
          answer: 'we cover most areas in asia'
        }
      ],
      ID: [
        {
          question: 'Apa keuntungan yang kami berikan?',
          answer:
            'Kepuasan pelanggan selalu menjadi prioritas kami. Kami Memotong biaya sumber daya manusia Anda, kami memiliki staf yang kompeten dan kredibel yang bertanggung jawab untuk anda'
        },
        {
          question: 'Bagaimana cara melacak kondisi barang saya?',
          answer:
            'Kami memiliki sistem manajerial transparan yang membuat proses pelacakan lebih terlihat untuk Anda dan memungkinkan Anda berkonsultasi untuk hasil yang lebih baik.'
        },
        {
          question: 'Apakah barang saya diasuransikan?',
          answer:
            'Tidak, Namun kami menawarkan biaya asuransi opsional yang menjamin keamanan barang Anda dan menawarkan penggantian untuk pengangkutan yang salah.'
        },
        {
          question: 'apakah pengiriman tersedia untuk seluruh dunia?',
          answer: 'kami mencakup semua wilayah di asia'
        }
      ]
    }
  }
};
