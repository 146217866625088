import React, { useEffect, useState } from 'react';
import { Pagination } from 'react-bootstrap';
import './style.css';

/*
[1] 2 3 4 5 6 7 8 9 10
1 [2] 3 4 5 6 7 8 9 10
1 2 [3] 4 5 6 7 8 9 10
1 2 3 [4] 5 6 7 8 9 10
1 2 3 4 [5] 6 7 8 9 10
1 2 3 4 5 [6] 7 8 9 10
2 3 4 5 6 [7] 8 9 10 11
3 4 5 6 7 [8] 9 10 11 12
5 6 7 8 9 [10] 11 12 13 14
6 7 8 9 10 [11] 12 13 14 15
6 7 8 9 10 11 [12] 13 14 15
6 7 8 9 10 11 12 [13] 14 15
*/
class Paginator extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      startPosition: 1,
      endPosition: props.totalPages < 5 ? props.totalPages : 5
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.totalPages !== prevProps.totalPages) {
      this.setState({
        ...this.state,
        endPosition: this.props.totalPages < 5 ? this.props.totalPages : 5
      });
    }
  }

  changeStartPosition = newPosition => {
    this.setState({
      ...this.state,
      startPosition: newPosition
    });
  };

  changeEndPosition = newPosition => {
    this.setState({
      ...this.state,
      endPosition: newPosition
    });
  };

  renderPaginationItem = (start, end) => {
    let { action, page } = this.props;
    let { startPosition, endPosition } = this.state;
    if (startPosition == null || endPosition == null) {
      return null;
    }

    let paginationArr = [];
    for (let i = start; i <= end; i++) {
      paginationArr.push(i);
    }

    return paginationArr.map((pageNumber, key) => {
      return pageNumber == page ? (
        <Pagination.Item active key={key}>
          {pageNumber}
        </Pagination.Item>
      ) : (
        <Pagination.Item
          key={key}
          onClick={() => {
            action(pageNumber);
            this.handlePageChange(pageNumber);
          }}
        >
          {pageNumber}
        </Pagination.Item>
      );
    });
  };

  handlePageChange = newPage => {
    let { totalPages } = this.props;
    if (newPage == 1) {
      this.changeStartPosition(newPage);
      this.changeEndPosition(
        newPage + 4 > totalPages ? totalPages : newPage + 4
      );
    } else if (newPage == totalPages) {
      this.changeEndPosition(totalPages);
      this.changeStartPosition(totalPages - 4 > 1 ? totalPages - 4 : 1);
    } else {
      let leftGap = totalPages - newPage > 1 ? 2 : 2 + (totalPages - newPage);
      let rightGap = newPage < 3 ? 2 + (3 - newPage) : 2;
      this.changeStartPosition(newPage - leftGap > 1 ? newPage - leftGap : 1);
      this.changeEndPosition(
        newPage + rightGap < totalPages ? newPage + rightGap : totalPages
      );
    }
  };

  render() {
    let { totalPages, page, action } = this.props;
    let { startPosition, endPosition } = this.state;
    return (
      <Pagination className='mt-4'>
        <Pagination.First
          onClick={() => {
            if (totalPages > 0) {
              action(1);
            }
            this.handlePageChange(1);
          }}
        />
        <Pagination.Prev
          onClick={() => {
            if (page - 1 >= 1) {
              action(page - 1);
              this.handlePageChange(page - 1);
            }
          }}
        />
        {this.renderPaginationItem(startPosition, endPosition)}
        <Pagination.Next
          onClick={() => {
            if (page + 1 <= totalPages) {
              action(page + 1);
              this.handlePageChange(page + 1);
            }
          }}
        />
        <Pagination.Last
          onClick={() => {
            if (totalPages > 0) {
              action(totalPages);
            }
            this.handlePageChange(totalPages);
          }}
        />
      </Pagination>
    );
  }
}

export default Paginator;
