import React from 'react';
import imageObject from '../../../utils/images';
import { Row, Col, Image } from 'react-bootstrap' 
import ServiceCard from './ServiceCard'

import '../style.css'

const serviceArrayEn = [{
  id: 1,
  title: "Freight Forwarder",
  image: imageObject.freightForward
}, {
  id: 2,
  title: "Fulfillment Storage",
  image: imageObject.fulfilmentStorage
}, {
  id: 3,
  title: "Custom Clearance",
  image: imageObject.customClearance
}]

const serviceArrayId = [{
  id: 1,
  title: "Freight Forwarder",
  image: imageObject.freightForward
}, {
  id: 2,
  title: "Gudang Umum",
  image: imageObject.fulfilmentStorage
}, {
  id: 3,
  title: "Custom Clearance",
  image: imageObject.customClearance
}]

const serviceObjc = {
  EN: serviceArrayEn,
  ID: serviceArrayId
}

const FormPicker = props => {
  return (
    <React.Fragment>
      <Row className="form-style">
        {serviceObjc[props.currentLanguage].map((service, key) => {
          return <ServiceCard key={key} picked={props.picked} handlePicked={props.handlePicked} service={service}/>
        })}
      </Row>
    </React.Fragment>
  );
}
 
export default FormPicker;