import React from 'react';
import MyContext from '../../middleware/context';
import { withRouter } from 'react-router-dom';
import axios from 'axios';
import HeroImage from '../../components/HeroImage';
import { url } from '../../utils/const';
import swal from 'sweetalert2';
import { Container, Modal } from 'react-bootstrap';
import CreateInput from './components/CreateInput.js';
import GradientContainer from '../../components/GradientContainer';
import FileInput from './components/FileInput.js';
import UploadSelect from './components/UploadSelect.js';
import ReactLoading from 'react-loading';
import NavigationCrumb from '../../components/NavigationCrumb';
import './style.css';
import AddFileCrumb from '../../components/AddFileCrumb';

let optionArr = [
  {
    value: 'suratJalan/masuk',
    text: 'Surat Jalan Masuk'
  },
  {
    value: 'suratJalan/keluar',
    text: 'Surat Jalan Keluar'
  },
  {
    value: 'stock',
    text: 'Stock'
  },
  {
    value: 'invoice',
    text: 'Invoice'
  },
  {
    value: 'users',
    text: 'User'
  },
  {
    value: 'users/admin',
    text: 'Admin User'
  }
];

let csvFormat = {
  'suratJalan/masuk': 'username | job_order | pdf_url | no_kontainer | stock',
  'suratJalan/keluar': 'username | job_order | pdf_url | stock_keluar',
  stock: 'username | job_order | description | excel_url',
  invoice: 'username | month | pdf_url',
  users: 'username | password',
  'users/admin': 'username | password'
};

class Create extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tapEnabled: true,
      show: false,
      per_page: 10,
      page: 1,
      isUpdate: props.location?.state?.isUpdate == true ? true : false,
      selectedFile: null,
      uploadType: 1,
      userArray: [],
      type:
        props.location.state == undefined
          ? 'suratJalan/masuk'
          : props.location.state.type,
      user_id:
        props.location?.state?.updateData?.user_id == undefined
          ? 1
          : props.location.state.updateData.user_id,
      job_order:
        props.location?.state?.updateData?.job_order == undefined
          ? ''
          : props.location.state.updateData.job_order,
      pdf_url:
        props.location?.state?.updateData?.pdf_url == undefined
          ? ''
          : props.location.state.updateData.pdf_url,
      description:
        props.location?.state?.updateData?.description == undefined
          ? ''
          : props.location.state.updateData.description,
      excel_url:
        props.location?.state?.updateData?.excel_url == undefined
          ? ''
          : props.location.state.updateData.excel_url,
      no_kontainer:
        props.location?.state?.updateData?.no_kontainer == undefined
          ? ''
          : props.location.state.updateData.no_kontainer,
      stock_masuk:
        props.location?.state?.updateData?.stock_masuk == undefined
          ? ''
          : props.location.state.updateData.stock_masuk,
      stock_keluar:
        props.location?.state?.updateData?.stock_keluar == undefined
          ? ''
          : props.location.state.updateData.stock_keluar,
      stock:
        props.location?.state?.updateData?.stock == undefined
          ? ''
          : props.location.state.updateData.stock,
      thumbnail_url:
        props.location?.state?.updateData?.thumbnail_url == undefined
          ? ''
          : props.location.state.updateData.thumbnail_url,
      kode_barang:
        props.location?.state?.updateData?.kode_barang == undefined
          ? ''
          : props.location.state.updateData.kode_barang,
      month:
        props.location?.state?.updateData?.month == undefined
          ? ''
          : props.location.state.updateData.month,
      username:
        props.location?.state?.updateData?.username == undefined
          ? ''
          : props.location.state.updateData.username,
      password:
        props.location?.state?.updateData?.password == undefined
          ? ''
          : props.location.state.updateData.password,
      selectedUser:
        props.location?.state?.updateData?.selectedUser == undefined
          ? null
          : props.location.state.updateData.selectedUser,
      isAdmin: false,
      usernameQuery: ''
    };
  }

  componentDidMount() {
    this.fetchAllUsers();
  }

  hideModal = () => {
    this.setState({
      ...this.state,
      show: false
    });
  };

  showModal = () => {
    this.setState({
      ...this.state,
      show: true
    });
  };

  mapValues = e => {
    this.setState({
      ...this.state,
      [e.target.name]: e.target.value
    });
  };

  fetchAllUsers = () => {
    let { page, per_page, usernameQuery } = this.state;

    let token = localStorage.getItem('token');

    axios
      .get(url + 'users', {
        headers: {
          Accept: 'application/json',
          Authorization: `Bearer ${token}`,
          page,
          per_page
        },
        params: {
          username: usernameQuery
        }
      })
      .then(response => {
        let userArr = response.data.result.data;
        let update = this.props.location?.state?.isUpdate;
        if (update !== true && this.state.selectedUser == null) {
          this.setState({
            ...this.state,
            userArray: userArr,
            totalPages: response.data.result.total_pages,
            selectedUser: userArr.length > 0 ? userArr[0] : {}
          });
        } else {
          this.setState({
            ...this.state,
            totalPages: response.data.result.total_pages,
            userArray: userArr
          });
        }
      })
      .catch(err => {
        swal.fire(
          'fetch error',
          'failed to fetch users, try refreshing the page',
          err
        );
      });
  };

  postData = () => {
    let {
      tapEnabled,
      uploadType,
      isUpdate,
      selectedFile,
      type,
      job_order,
      pdf_url,
      description,
      no_kontainer,
      excel_url,
      stock_masuk,
      stock_keluar,
      username,
      password,
      month,
      stock,
      selectedUser
    } = this.state;

    if (tapEnabled == false) {
      return;
    }

    if (uploadType == 2) {
      this.setState(
        {
          ...this.state,
          tapEnabled: false
        },
        () => {
          if (selectedFile) {
            this.showModal();
            var formData = new FormData();
            formData.append('myfile', selectedFile);
            axios
              .post(url + type + '/csv', formData, {
                headers: {
                  'Content-Type': 'multipart/form-data',
                  Authorization: `Bearer ${localStorage.getItem('token')}`,
                  Accept: 'application/json'
                }
              })
              .then(response => {
                this.hideModal();
                let { success, failure, failureArr } = response.data.result;
                let query = `succesfuly inserted ${success} files, failed to insert ${failure} files`;
                if (failure > 0) {
                  query += `\n with Error Rows: ${JSON.stringify(failureArr)}`;
                }
                swal
                  .fire('success', query, 'success')
                  .then(() => {
                    this.setState({
                      ...this.state,
                      tapEnabled: true
                    });
                  })
                  .catch(() => {
                    this.setState({
                      ...this.state,
                      tapEnabled: true
                    });
                  });
              })
              .catch(err => {
                this.hideModal();
                swal
                  .fire(
                    'Oops something went wrong, check if upload file changed',
                    err,
                    'error'
                  )
                  .then(() => {
                    this.setState({
                      ...this.state,
                      tapEnabled: true
                    });
                  })
                  .catch(() => {
                    this.setState({
                      ...this.state,
                      tapEnabled: true
                    });
                  });
              });
          }
        }
      );
    } else {
      let dataObj = {
        'suratJalan/masuk':
          isUpdate == false
            ? {
                user_id: selectedUser.id,
                job_order,
                pdf_url,
                stock_masuk,
                no_kontainer
              }
            : {
                user_id: selectedUser.id,
                pdf_url,
                stock_masuk,
                no_kontainer,
                job_order
              },
        'suratJalan/keluar': {
          user_id: selectedUser.id,
          job_order,
          pdf_url,
          stock_keluar
        },
        invoice: {
          user_id: selectedUser.id,
          pdf_url,
          month
        },
        stock:
          isUpdate == false
            ? {
                user_id: selectedUser.id,
                job_order,
                description,
                excel_url
              }
            : {
                user_id: selectedUser.id,
                job_order,
                description,
                excel_url,
                no_kontainer,
                stock
              },
        users: {
          username,
          password
        },
        'users/admin': {
          username,
          password
        }
      };

      if (isUpdate == true) {
        let data = dataObj[type];
        let id = this.props.location.state.updateData.id;

        this.setState(
          {
            ...this.state,
            tapEnabled: false
          },
          () => {
            this.showModal();
            axios
              .put(url + `${type}` + `/${id}`, data, {
                headers: {
                  Authorization: `Bearer ${localStorage.getItem('token')}`,
                  Accept: 'application/json'
                }
              })
              .then(response => {
                let { result } = response.data;
                this.hideModal();
                swal
                  .fire(
                    'success',
                    `successfully updated ${type} with id: ${result.id}`,
                    'success'
                  )
                  .then(() => {
                    this.setState({
                      ...this.state,
                      tapEnabled: true
                    });
                  })
                  .catch(() => {
                    this.setState({
                      ...this.state,
                      tapEnabled: true
                    });
                  });
              })
              .catch(err => {
                this.hideModal();
                swal
                  .fire(
                    'Oops',
                    `err found: ${err.response?.data?.result?.sqlMessage}`,
                    'error'
                  )
                  .then(() => {
                    this.setState({
                      ...this.state,
                      tapEnabled: true
                    });
                  })
                  .catch(() => {
                    this.setState({
                      ...this.state,
                      tapEnabled: true
                    });
                  });
              });
          }
        );
      } else {
        if (localStorage.getItem('admin') == true) {
          this.setState(
            {
              ...this.state,
              tapEnabled: false
            },
            () => {
              this.showModal();
              let data = dataObj[type];
              axios
                .post(url + `${type}`, data, {
                  headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                    Accept: 'application/json'
                  }
                })
                .then(response => {
                  let { result } = response.data;
                  this.hideModal();
                  swal
                    .fire(
                      'success',
                      `successfully created ${type} with id: ${result.id}`,
                      'success'
                    )
                    .then(() => {
                      this.setState(
                        {
                          ...this.state,
                          tapEnabled: true
                        },
                        () => {
                          if (type == 'users') {
                            this.fetchAllUsers();
                          }
                        }
                      );
                    })
                    .catch(() => {
                      this.setState({
                        ...this.state,
                        tapEnabled: true
                      });
                    });
                })
                .catch(err => {
                  this.hideModal();
                  swal
                    .fire(
                      'Oops',
                      `err found: ${err.response?.data?.result?.sqlMessage}`,
                      'error'
                    )
                    .then(() => {
                      this.setState({
                        ...this.state,
                        tapEnabled: true
                      });
                    })
                    .catch(() => {
                      this.setState({
                        ...this.state,
                        tapEnabled: true
                      });
                    });
                });
            }
          );
        }
      }
    }
  };

  changeUploadType = uploadType => {
    this.setState({
      ...this.state,
      uploadType: uploadType
    });
  };

  renderInputs = type => {
    let { isUpdate } = this.state;
    if (type == 'suratJalan/masuk') {
      return (
        <>
          {isUpdate == false ? (
            <>
              <CreateInput
                text='JOB ORDER'
                type='text'
                placeholder='JOB ORDER'
                name='job_order'
                value={this.state.job_order}
                valueChange={this.mapValues}
              />
              {/* <CreateInput
                text='NO KONTAINER'
                type='text'
                placeholder='NO KONTAINER'
                name='no_kontainer'
                value={this.state.no_kontainer}
                valueChange={this.mapValues}
              />
              <CreateInput
                text='JUMLAH BARANG MASUK'
                type='number'
                placeholder='JUMLAH BARANG MASUK'
                name='stock_masuk'
                value={this.state.stock_masuk}
                valueChange={this.mapValues}
              /> */}
            </>
          ) : null}
          <CreateInput
            text='PDF URL'
            type='text'
            placeholder='PDF URL'
            name='pdf_url'
            value={this.state.pdf_url}
            valueChange={this.mapValues}
          />
          <CreateInput
            text='NO KONTAINER'
            type='text'
            placeholder='NO KONTAINER'
            name='no_kontainer'
            value={this.state.no_kontainer}
            valueChange={this.mapValues}
          />
          <CreateInput
            text='JUMLAH BARANG MASUK'
            type='number'
            placeholder='JUMLAH BARANG MASUK'
            name='stock_masuk'
            value={this.state.stock_masuk}
            valueChange={this.mapValues}
          />
        </>
      );
    } else if (type == 'suratJalan/keluar') {
      return (
        <>
          <CreateInput
            text='JOB ORDER'
            type='text'
            placeholder='JOB ORDER'
            name='job_order'
            value={this.state.job_order}
            valueChange={this.mapValues}
          />
          <CreateInput
            text='PDF URL'
            type='text'
            placeholder='PDF URL'
            name='pdf_url'
            value={this.state.pdf_url}
            valueChange={this.mapValues}
          />
          <CreateInput
            text='JUMLAH KELUAR'
            type='number'
            placeholder='JUMLAH KELUAR'
            name='stock_keluar'
            value={this.state.stock_keluar}
            valueChange={this.mapValues}
          />
        </>
      );
    } else if (type == 'stock') {
      return (
        <>
          <CreateInput
            text='JOB ORDER'
            type='text'
            placeholder='JOB ORDER'
            name='job_order'
            disabled={isUpdate}
            value={this.state.job_order}
            valueChange={this.mapValues}
          />
          <CreateInput
            text='DESCRIPTION'
            type='text'
            placeholder='DESCRIPTION'
            name='description'
            value={this.state.description}
            valueChange={this.mapValues}
          />
          <CreateInput
            text='EXCEL URL'
            type='text'
            placeholder='EXCEL URL'
            name='excel_url'
            value={this.state.excel_url}
            valueChange={this.mapValues}
          />
          {isUpdate == true ? (
            <>
              <CreateInput
                text='NO KONTAINER'
                type='text'
                placeholder='NO KONTAINER'
                name='no_kontainer'
                value={this.state.no_kontainer}
                valueChange={this.mapValues}
              />
              <CreateInput
                text='STOCK'
                type='number'
                placeholder='STOCK'
                name='stock'
                value={this.state.stock}
                valueChange={this.mapValues}
              />
            </>
          ) : null}
        </>
      );
    } else if (type == 'invoice') {
      return (
        <>
          <CreateInput
            text='PDF URL'
            type='text'
            placeholder='PDF URL'
            name='pdf_url'
            value={this.state.pdf_url}
            valueChange={this.mapValues}
          />
          <CreateInput
            text='MONTH'
            type='number'
            placeholder='MONTH'
            name='month'
            value={this.state.month}
            valueChange={this.mapValues}
          />
        </>
      );
    } else if (type == 'users') {
      return (
        <>
          <CreateInput
            text='USERNAME'
            type='text'
            placeholder='USERNAME'
            name='username'
            value={this.state.username}
            valueChange={this.mapValues}
          />
          <CreateInput
            text='PASSWORD'
            type='text'
            placeholder='PASSWORD'
            name='password'
            value={this.state.password}
            valueChange={this.mapValues}
          />
        </>
      );
    } else if (type == 'users/admin') {
      return (
        <>
          <CreateInput
            text='USERNAME'
            type='text'
            placeholder='USERNAME'
            name='username'
            value={this.state.username}
            valueChange={this.mapValues}
          />
          <CreateInput
            text='PASSWORD'
            type='text'
            placeholder='PASSWORD'
            name='password'
            value={this.state.password}
            valueChange={this.mapValues}
          />
        </>
      );
    }
  };

  setSelectedFile = fileObject => {
    this.setState({
      ...this.state,
      selectedFile: fileObject
    });
  };

  renderUserDropDown = () => {
    let { type, selectedUser, totalPages, page, usernameQuery } = this.state;
    if (type == 'users' || type == 'users/admin') {
      return null;
    } else {
      return (
        <CreateInput
          text='USER'
          type='user'
          placeholder='pick user'
          searchPlaceHolder='Search User'
          searchValue={usernameQuery}
          searchName='usernameQuery'
          searchValueChange={this.mapValues}
          searchAction={() => {
            this.fetchAllUsers();
          }}
          name='selectedUser'
          totalPages={totalPages}
          page={page}
          option={this.state.userArray}
          action={this.handlePageChange}
          value={selectedUser}
          valueChange={this.mapValues}
        />
      );
    }
  };

  handlePageChange = page => {
    if (page != this.state.page) {
      this.setState(
        {
          ...this.state,
          page: page
        },
        () => {
          this.fetchAllUsers();
        }
      );
    }
  };

  renderCreateOrUpdate = () => {
    let {
      isUpdate,
      type,
      uploadType,
      selectedFile,
      selectedUser,
      totalPages,
      page,
      usernameQuery
    } = this.state;
    if (isUpdate == false) {
      return (
        <>
          <UploadSelect
            action={this.changeUploadType}
            selected={this.state.uploadType}
          />
          <CreateInput
            text='TYPE'
            type='form'
            placeholder=''
            name='type'
            option={optionArr}
            value={type}
            valueChange={this.mapValues}
          />
          {uploadType == 1 ? (
            <>
              {this.renderUserDropDown()}
              {this.renderInputs(this.state.type)}
            </>
          ) : (
            <>
              <p className='matt'>{`CSV Format: ${csvFormat[type]}`}</p>
              <FileInput
                selectedFile={selectedFile}
                action={this.setSelectedFile}
              />
            </>
          )}
        </>
      );
    } else {
      return (
        <>
          {uploadType == 1 ? (
            <>
              {type != 'users' ? (
                <CreateInput
                  text='USER'
                  type='user'
                  placeholder='pick user'
                  searchPlaceHolder='Search User'
                  searchValue={usernameQuery}
                  searchName='usernameQuery'
                  searchValueChange={this.mapValues}
                  searchAction={() => {
                    this.fetchAllUsers();
                  }}
                  totalPages={totalPages}
                  page={page}
                  name='selectedUser'
                  option={this.state.userArray}
                  action={this.handlePageChange}
                  value={selectedUser}
                  valueChange={this.mapValues}
                />
              ) : null}
              {this.renderInputs(this.state.type)}
            </>
          ) : (
            <>
              <p className='matt'>FORMAT: dog | user | dog | woof</p>
              <FileInput
                selectedFile={selectedFile}
                action={this.setSelectedFile}
              />
            </>
          )}
        </>
      );
    }
  };

  handleNavigationToPage = page => {
    // portal sjm sjk stock invoice user
    let navigationArr = [
      'portal',
      'surat-jalan-masuk',
      'surat-jalan-keluar',
      'stock',
      'invoice',
      'user'
    ];
    this.props.history.push(navigationArr[page]);
  };

  render() {
    let { isUpdate, show } = this.state;
    return (
      <>
        <HeroImage title='File Manager' />
        <Container>
          <div className={'create-container mb-5'}>
            <NavigationCrumb
              className='mb-2'
              action={this.handleNavigationToPage}
            />
            <h2 className={'mt-1 underlined'} style={{ color: '#47657A' }}>
              FILE MANAGER
            </h2>
            <div
              style={{
                width: '90%',
                overflow: 'auto',
                display: 'flex',
                justifyContent: 'center',
                flexDirection: 'column',
                alignItems: 'center'
              }}
            >
              {this.renderCreateOrUpdate()}
              <GradientContainer
                action={this.postData}
                text={isUpdate == false ? 'SUBMIT' : 'UPDATE'}
              />
            </div>
          </div>
        </Container>
        <Modal
          show={show}
          centered
          className='dogerino'
          onHide={() => {}}
          animation={true}
        >
          <div style={{ margin: '0 auto', width: '10%' }}>
            <ReactLoading
              type={'spin'}
              color={'#ffffff'}
              height={50}
              width={50}
            />
          </div>
        </Modal>
      </>
    );
  }
}

Create.contextType = MyContext;

export default withRouter(Create);
