import React from 'react';
import './style.css';
import { withRouter } from 'react-router-dom';
import HeroImage from '../../components/HeroImage';
import axios from 'axios';
import { url } from '../../utils/const';
import swal from 'sweetalert2';
import Paginator from '../../components/Pagination';
import AddFileCrumb from '../../components/AddFileCrumb';
import InvoiceRow from './components/InvoiceRow';
import FilterDate from '../../components/FilterDate';
import FilterUser from '../../components/FilterUser';
import dateFormat from 'dateformat';

class Invoice extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      invoiceData: [],
      per_page: 10,
      page: 1,
      totalPages: null,
      dateSwitch: false,
      start_date: new Date(),
      end_date: new Date(),
      usernameSwitch: false,
      screenWidth: window.innerWidth,
      selectedUser: {
        username: null,
        id: null
      }
    };
  }

  componentDidMount() {
    this.fetchInvoiceData();
    document.title = `Invoice | ATS`;
    window.addEventListener('resize', this.handleWindowResize);
    return () => {
      window.removeEventListener('resize', this.handleWindowResize);
    };
  }

  handleWindowResize = () => {
    this.setState({
      ...this.state,
      screenWidth: window.innerWidth
    });
  };

  fetchInvoiceData() {
    let {
      per_page,
      page,
      dateSwitch,
      start_date,
      end_date,
      selectedUser,
      usernameSwitch
    } = this.state;
    let headers = {
      Accept: 'application/json',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
      per_page: per_page,
      page: page
    };

    if (dateSwitch == true) {
      headers['start_date'] = dateFormat(start_date, 'yyyy-mm-dd');
      headers['end_date'] = dateFormat(end_date, 'yyyy-mm-dd');
    }

    if (usernameSwitch == true && selectedUser.id != null) {
      headers['user_id'] = selectedUser.id;
    }

    let urlEndpoint =
      localStorage.getItem('admin') == true
        ? url + 'invoice'
        : url + 'invoice/user';

    axios
      .get(urlEndpoint, {
        headers
      })
      .then(response => {
        let { data, total_pages } = response.data.result;
        this.setState({
          ...this.state,
          invoiceData: data,
          totalPages: total_pages
        });
      })
      .catch(err => {
        swal.fire('Ooops', 'failed to get invoice data', 'error');
      });
  }

  handleUserSwitch = checked => {
    this.setState(
      {
        ...this.state,
        usernameSwitch: checked
      },
      () => {
        this.fetchInvoiceData();
      }
    );
  };

  handleChangeDateSwitch = checked => {
    this.setState(
      {
        ...this.state,
        dateSwitch: checked
      },
      () => {
        this.fetchInvoiceData();
      }
    );
  };

  handleDateChanged = (dateType, newDate) => {
    this.setState({
      ...this.state,
      [dateType]: newDate
    });
  };

  handlePageChange = page => {
    if (page != this.state.page) {
      this.setState(
        {
          ...this.state,
          page: page
        },
        () => {
          this.fetchInvoiceData();
        }
      );
    }
  };

  mapValues = e => {
    this.setState({
      ...this.state,
      [e.target.name]: e.target.value
    });
  };

  openPdf(url) {
    window.open(url, '_blank');
  }

  handleNavigationToCreate = page => {
    let pageArr = [
      'suratJalan/masuk',
      'suratJalan/keluar',
      'stock',
      'invoice',
      'users',
      'users/admin'
    ];
    this.props.history.push({
      pathname: '/create',
      state: { type: pageArr[page] }
    });
  };

  updateAction = data => {
    this.props.history.push({
      pathname: '/create',
      state: {
        type: 'invoice',
        isUpdate: true,
        updateData: data
      }
    });
  };

  deleteAction = data => {
    swal
      .fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
      })
      .then(result => {
        if (result.value) {
          let headers = {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
            Accept: `application/json`
          };
          axios
            .delete(url + 'invoice' + `/${data.id}`, {
              headers
            })
            .then(_ => {
              swal
                .fire(
                  'Deleted!',
                  `delete file with id: ${data.id} has been deleted.`,
                  'success'
                )
                .then(_ => {
                  this.fetchInvoiceData();
                });
            })
            .catch(_ => {
              swal.fire('Ooops', 'failed to delete data', 'error');
            });
        }
      });
  };

  render() {
    let {
      page,
      totalPages,
      dateSwitch,
      start_date,
      end_date,
      usernameSwitch,
      screenWidth
    } = this.state;
    return (
      <div>
        <HeroImage title='Invoice' />
        <div className='sj-container mb-3'>
          {localStorage.getItem('admin') == true ? (
            <AddFileCrumb action={this.handleNavigationToCreate} />
          ) : null}
          <h2 className={'mt-3 underlined'} style={{ color: '#47657A' }}>
            INVOICE
          </h2>
          <div className='filter-option-container mt-2 mb-2'>
            <FilterDate
              checked={dateSwitch}
              action={this.handleChangeDateSwitch}
              start_date={start_date}
              end_date={end_date}
              dateChanged={this.handleDateChanged}
            />
            {localStorage.getItem('admin') == true ? (
              <div className={screenWidth < 576 ? 'mt-3' : 'ml-4'}>
                <FilterUser
                  title='Filter user'
                  action={this.handleUserSwitch}
                  checked={usernameSwitch}
                  // joker
                  selectUser={userObject => {
                    this.mapValues({
                      target: {
                        name: 'selectedUser',
                        value: userObject
                      }
                    });
                  }}
                />
              </div>
            ) : null}
          </div>
          <div
            style={
              {
                // width: '80%',
                // overflow: 'auto',
                // background: `transparent linear-gradient(90deg, #258bd345 0%, #8ec6414a 100%)
                // 0% 0% no-repeat padding-box`,
                // marginlef: -100%;
                // margin-right: -100%;
                // text-align: center;
              }
            }
          >
            {this.state.invoiceData.length == 0
              ? null
              : this.state.invoiceData.map((data, index) => {
                  return (
                    <InvoiceRow
                      key={index}
                      screenWidth={screenWidth}
                      data={data}
                      openPdf={this.openPdf}
                      updateAction={this.updateAction}
                      deleteAction={this.deleteAction}
                    />
                  );
                })}
          </div>
          {totalPages != null ? (
            <Paginator
              totalPages={totalPages}
              page={page}
              action={this.handlePageChange}
            />
          ) : null}
        </div>
      </div>
    );
  }
}

export default withRouter(Invoice);
