import React from 'react';

const FileInput = props => {
  let { selectedFile, action } = props;

  const fileChanged = e => {
    action(e.target.files[0]);
  };

  return (
    <>
      <div className='upload-btn-wrapper '>
        <button className='btn-upload'>
          {selectedFile == null
            ? 'select a .csv file'
            : `selected ${selectedFile.name}`}
        </button>
        <input type='file' accept='.csv' name='myfile' onChange={fileChanged} />
      </div>
    </>
  );
};

export default FileInput;
