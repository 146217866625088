import React from 'react';
import './style.css';
import Switch from 'react-switch';
import { Form } from 'react-bootstrap';

const FilterSwitch = props => {
  let {
    title,
    checked,
    action,
    placeholder,
    name,
    value,
    valueChanged,
    type
  } = props;

  if (type == 'text') {
    return (
      <div className='filter-container'>
        <div className='switch-container'>
          <p className='filter-text'>{title}</p>
          <Switch
            className='level-switch'
            onChange={action}
            checked={checked}
            height={21}
            width={47}
          />
        </div>
        <Form.Control
          className={'filter-form-container level-filter-container'}
          type='text'
          placeholder={placeholder}
          name={name}
          value={value}
          onChange={valueChanged}
        />
      </div>
    );
  } else {
    return (
      <div className='filter-container'>
        <div className='switch-container'>
          <p className='filter-text'>{title}</p>
          <Switch
            className='level-switch'
            onChange={action}
            checked={checked}
            height={21}
            width={47}
          />
        </div>
        <Form.Control
          className={'filter-form-container level-filter-container'}
          type='number'
          placeholder={placeholder}
          name={name}
          value={value}
          onChange={valueChanged}
        />
      </div>
    );
  }
};

export default FilterSwitch;
