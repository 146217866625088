import React, { Component } from 'react';

const MyContext = React.createContext();
const languages = {
  ENGLISH: 'EN',
  BAHASA: 'ID'
};

export class MyProvider extends Component {
  state = {
    currentLanguage: languages.ENGLISH,
    credentials: null
  };

  setCredentials = data => {
    this.setState(
      {
        credentials: data
      },
      () => {
        console.log('set credentials with', this.state.credentials);
      }
    );
  };

  changeLanguage = language => {
    this.setState({
      currentLanguage: languages[language]
    });
  };

  render() {
    return (
      <MyContext.Provider
        value={{
          state: this.state,
          changeLanguage: this.changeLanguage,
          setCredentials: this.setCredentials
        }}
      >
        {this.props.children}
      </MyContext.Provider>
    );
  }
}

export default MyContext;
