import React, { useState } from 'react';
import { Col, Form, Button } from 'react-bootstrap';
import '../style.css';

const ContactForm = props => {
  const { onSubmit, onChange } = props;
  /*
name
email
phone number
type(delete soon)
address(optional)
destination(optional)
rent duration(optional)
cbm(optional)
hscode(optional)
medium(optional)
 let { name, email, phone, address, destination, period, cbm, hscode, medium, description } = req.body
 
*/

  const [form, setState] = useState({
    name: '',
    phone: '',
    email: '',
    destination: '',
    address: '',
    cbm: '',
    hscode: '',
    medium: '',
    description: ''
  });

  const updateField = e => {
    setState({
      ...form,
      [e.target.name]: e.target.value
    });
  };

  const refreshState = () => {
    setState({
      ...form,
      name: '',
      phone: '',
      email: '',
      destination: '',
      address: '',
      cbm: '',
      hscode: '',
      medium: '',
      description: ''
    });
  };

  return (
    <Form method='POST' onSubmit={onSubmit} onChange={onChange}>
      <Form.Row>
        <Col xs={6}>
          <Form.Control
            className={form.name == '' ? 'mb-3 empty-form' : 'mb-3'}
            type='text'
            placeholder='Name'
            value={form.name}
            name='name'
            onChange={updateField}
          />
          <Form.Control
            className={form.email == '' ? 'mb-3 empty-form' : 'mb-3'}
            type='email'
            placeholder='Email'
            name='email'
            value={form.email}
            name='email'
            onChange={updateField}
          />
          <Form.Control
            className='mb-3'
            type='text'
            placeholder='Address Location (Optional)'
            value={form.address}
            name='address'
            onChange={updateField}
          />
          <Form.Control
            className='mb-3'
            type='text'
            placeholder='HS Code (Optional)'
            value={form.hscode}
            name='hscode'
            onChange={updateField}
          />
        </Col>
        <Col xs={6}>
          <Form.Control
            className={form.phone == '' ? 'mb-3 empty-form' : 'mb-3'}
            type='number'
            placeholder='Phone Number'
            value={form.phone}
            name='phone'
            onChange={updateField}
          />
          <Form.Control
            className='mb-3'
            type='text'
            placeholder='Destination (Optional)'
            value={form.destination}
            name='destination'
            onChange={updateField}
          />
          <Form.Control
            className='mb-3'
            type='number'
            placeholder='Cubic Meter / CBM (Optional)'
            value={form.cbm}
            name='cbm'
            onChange={updateField}
          />
          <Form.Control as='select' name='medium' onChange={updateField}>
            <option value='' disabled selected hidden>
              Choose medium transport
            </option>
            <option value='Sea'>Sea</option>
            <option value='Air'>Air</option>
          </Form.Control>
        </Col>
      </Form.Row>
      <Form.Row>
        <Col>
          <Form.Control
            className='mb-3'
            as='textarea'
            rows='7'
            placeholder='Description (Optional)'
            value={form.description}
            name='description'
            onChange={updateField}
          />
        </Col>
      </Form.Row>
      <div className='text-left'>
        {props.enabled ? (
          <Button
            className='bg-blue'
            type='button'
            onClick={() => {
              props.toggle(false);
              onSubmit({
                ...form,
                refreshState
              });
            }}
          >
            Submit
          </Button>
        ) : (
          <Button className='bg-blue' disabled>
            Submit
          </Button>
        )}
      </div>
    </Form>
  );
};

export default ContactForm;
