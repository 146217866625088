import React, { useEffect, useState } from 'react';

import { Container, Row, Col } from 'react-bootstrap';

import HeroImage from '../../components/HeroImage';
import ContactForm from './components/ContactForm';
import ContactInfo from './components/ContactInfo';
import FormPicker from './components/FormPicker';
import Maps from './components/Maps';
import swal from 'sweetalert2';
import dictionary from './words';
import MyContext from '../../middleware/context';
import imageObject from '../../utils/images';

const Contact = props => {
  const { title } = props;
  // set state here
  const [currentPick, changePick] = useState(1);
  const [enabled, toggle] = useState(true);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  useEffect(() => {
    document.title = `${title} | ATS`;
    window.addEventListener('resize', handleWindowResize);
    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  const handleWindowResize = () => {
    setScreenWidth(window.innerWidth);
  };

  const handlePicked = picked => {
    changePick(picked);
  };

  const sendMail = data => {
    if (data.name == '' || data.phone == '' || data.email == '') {
      swal.fire(
        'Missing Fields',
        'Please fill the required fields marked in red',
        'warning'
      );
      toggle(true);
    } else {
      let topicObject = {
        1: 'Freight Forwarder',
        2: 'Gudang Umum',
        3: 'Custom Clearance'
      };
      let template_params = {
        name: data.name,
        email: data.email,
        phone: data.phone,
        address: data.address || '',
        destination: data.destination || '',
        cbm: data.cbm || '',
        hscode: data.hscode || '',
        medium: data.medium || '',
        description: `inquired about: ${
          topicObject[currentPick]
        }, ${data.description || ''}`
      };
      var service_id = 'default_service';
      var template_id = 'template_2ihArpfV';

      window.emailjs
        .send(service_id, template_id, template_params)
        .then(success => {
          toggle(true);
          swal.fire(
            'Success',
            'Your Inquiry has been sent, Our personel will contact you shortly',
            'success'
          );
          data.refreshState();
        })
        .catch(error => {
          toggle(true);
          swal.fire('Ooops', 'Something went wrong', 'error');
        });
    }
  };

  return (
    <MyContext.Consumer>
      {context => {
        const { currentLanguage } = context.state;
        return (
          <>
            <HeroImage title='' image={imageObject.contactHeader} />
            <Container className='page-container'>
              <div className='mb-4'>
                <h2>{dictionary.subheading[currentLanguage]}</h2>
                <p>{dictionary.contactSubtext1[currentLanguage]}</p>
                <p>{dictionary.contactSubtext2[currentLanguage]}</p>
              </div>
              <div className='mb-3'>
                <h3 className='text-green minimized'>
                  {dictionary.selectText[currentLanguage]}
                </h3>
              </div>
              <div className='mb-3'>
                <FormPicker
                  currentLanguage={currentLanguage}
                  handlePicked={handlePicked}
                  picked={currentPick}
                />
              </div>
              <Row className='mb-5'>
                {screenWidth > 1100 ? (
                  <React.Fragment>
                    <Col md={5}>
                      <ContactInfo />
                    </Col>
                    <Col md={7}>
                      <ContactForm
                        onChange={() => {}}
                        onSubmit={sendMail}
                        enabled={enabled}
                        toggle={toggle}
                      />
                    </Col>
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <Col className='mb-3' md={12}>
                      <ContactForm
                        onChange={() => {}}
                        onSubmit={sendMail}
                        enabled={enabled}
                        toggle={toggle}
                      />
                    </Col>
                    <Col md={12}>
                      <ContactInfo />
                    </Col>
                  </React.Fragment>
                )}
              </Row>
            </Container>
            <Maps
              isMarkerShown
              googleMapURL='https://maps.googleapis.com/maps/api/js?key=AIzaSyDiJ2UL8brjoi6nuTL2qg4iWDUVxm8bdFs&v=3.exp&libraries=geometry,drawing,places'
              loadingElement={<div style={{ height: `100%` }} />}
              containerElement={<div style={{ height: `400px` }} />}
              mapElement={<div style={{ height: `100%` }} />}
            />
          </>
        );
      }}
    </MyContext.Consumer>
  );
};

export default Contact;
