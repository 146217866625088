import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import './style.css';

const index = ({ features, context, dictionary }) => {
  return (
    <div className='data__wrapper'>
      <div className='data__header'>
        <span>
          {dictionary.dataSectionTitle[context.state.currentLanguage]}
        </span>
      </div>
      <div>
        <Container>
          <div>
            <h2 className='data__title'>
              <span>
                {dictionary.dataSubtitleHeading[context.state.currentLanguage]}
              </span>
            </h2>
            <div className='data__description'>
              <span>
                {dictionary.dataSectionSubtitle[context.state.currentLanguage]}
              </span>
            </div>
          </div>
          <Row>
            {features.map((feature, index) => (
              <Col key={index}>
                <div className='feature__header'>
                  <div>
                    <img
                      src={feature.image}
                      alt={`${feature.name} ${feature.nameCont}`}
                    />
                    <span
                      className={`${index === 0 ? 'text-green' : 'text-blue'}`}
                    >
                      {feature.total}+
                    </span>
                  </div>
                  <div className='feature__title'>
                    <span
                      className={`${index === 0 ? 'text-green' : 'text-blue'}`}
                    >
                      {feature.name}
                    </span>
                    <span> {feature.nameCont}</span>
                  </div>
                  <div className='feature__description'>
                    {feature.description[context.state.currentLanguage]}
                  </div>
                </div>
              </Col>
            ))}
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default index;
