import React from 'react';
import './style.css';
import { withRouter } from 'react-router-dom';
import HeroImage from '../../components/HeroImage';
import axios from 'axios';
import { url } from '../../utils/const';
import MyContext from '../../middleware/context';
import swal from 'sweetalert2';
import SuratJalanKeluarRow from './components/SuratJalanKeluarRow.js';
import Paginator from '../../components/Pagination';
import AddFileCrumb from '../../components/AddFileCrumb';
import FilterSwitch from '../../components/FilterSwitch';
import FilterDate from '../../components/FilterDate';
import FilterUser from '../../components/FilterUser';
import dateFormat from 'dateformat';

class SuratJalan extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dataSuratMasuk: [],
      dataSuratKeluar: [],
      per_page: 10,
      page: 1,
      totalPages: null,
      jobOrderSwitch: false,
      dateSwitch: false,
      job_order: 1,
      start_date: new Date(),
      end_date: new Date(),
      usernameSwitch: false,
      screenWidth: window.innerWidth,
      selectedUser: {
        id: null,
        username: null
      }
    };
  }

  componentDidMount() {
    this.fetchSuratJalanKeluar();
    document.title = `SJM | ATS`;
    window.addEventListener('resize', this.handleWindowResize);
    return () => {
      window.removeEventListener('resize', this.handleWindowResize);
    };
  }

  handleWindowResize = () => {
    this.setState({
      ...this.state,
      screenWidth: window.innerWidth
    });
  };

  fetchSuratJalanKeluar() {
    let {
      per_page,
      page,
      jobOrderSwitch,
      job_order,
      dateSwitch,
      start_date,
      end_date,
      usernameSwitch,
      selectedUser
    } = this.state;
    let headers = {
      Accept: 'application/json',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
      per_page: per_page,
      page: page
    };

    if (jobOrderSwitch == true) {
      headers['job_order'] = job_order;
    }
    if (dateSwitch == true) {
      headers['start_date'] = dateFormat(start_date, 'yyyy-mm-dd');
      headers['end_date'] = dateFormat(end_date, 'yyyy-mm-dd');
    }
    if (usernameSwitch == true && selectedUser.id != null) {
      headers['user_id'] = selectedUser.id;
    }

    let urlEndpoint =
      localStorage.getItem('admin') == true
        ? url + 'suratJalan/keluar/semua'
        : url + 'suratJalan/keluar/semua/user';

    axios
      .get(urlEndpoint, {
        headers
      })
      .then(response => {
        let { data, total_pages } = response.data.result;
        this.setState({
          ...this.state,
          dataSuratMasuk: data,
          totalPages: total_pages
        });
      })
      .catch(err => {
        swal.fire('Ooops', 'failed to get data', 'error');
      });
  }

  handleChangeJobOrderSwitch = checked => {
    this.setState(
      {
        ...this.state,
        jobOrderSwitch: checked
      },
      () => {
        this.fetchSuratJalanKeluar();
      }
    );
  };

  handleChangeDateSwitch = checked => {
    this.setState(
      {
        ...this.state,
        dateSwitch: checked
      },
      () => {
        this.fetchSuratJalanKeluar();
      }
    );
  };

  handleDateChanged = (dateType, newDate) => {
    this.setState({
      ...this.state,
      [dateType]: newDate
    });
  };

  handleUserSwitch = checked => {
    this.setState(
      {
        ...this.state,
        usernameSwitch: checked
      },
      () => {
        this.fetchSuratJalanKeluar();
      }
    );
  };

  openPdf(url) {
    window.open(url, '_blank');
  }

  handlePageChange = page => {
    if (page != this.state.page) {
      this.setState(
        {
          ...this.state,
          page: page
        },
        () => {
          this.fetchSuratJalanKeluar();
        }
      );
    }
  };

  handleNavigationToCreate = page => {
    let pageArr = [
      'suratJalan/masuk',
      'suratJalan/keluar',
      'stock',
      'invoice',
      'users',
      'users/admin'
    ];
    this.props.history.push({
      pathname: '/create',
      state: { type: pageArr[page] }
    });
  };

  mapValues = e => {
    this.setState(
      {
        ...this.state,
        [e.target.name]: e.target.value
      },
      () => {}
    );
  };

  updateAction = data => {
    this.props.history.push({
      pathname: '/create',
      state: {
        type: 'suratJalan/keluar',
        isUpdate: true,
        updateData: data
      }
    });
  };

  deleteAction = data => {
    swal
      .fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
      })
      .then(result => {
        if (result.value) {
          let headers = {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
            Accept: `application/json`
          };
          axios
            .delete(url + 'suratJalan/keluar/many' + `/${data.job_order}`, {
              headers
            })
            .then(_ => {
              swal
                .fire(
                  'Deleted!',
                  `deleted all files with job_order: ${data.job_order} `,
                  'success'
                )
                .then(_ => {
                  this.fetchSuratJalanKeluar();
                });
            })
            .catch(_ => {
              swal.fire('Ooops', 'failed to delete data', 'error');
            });
        }
      });
  };

  deleteActionSingle = id => {
    swal
      .fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
      })
      .then(result => {
        if (result.value) {
          let headers = {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
            Accept: `application/json`
          };
          axios
            .delete(url + 'suratJalan/keluar' + `/${id}`, {
              headers
            })
            .then(_ => {
              swal
                .fire(
                  'Deleted!',
                  `successfully deleted file with id: ${id} `,
                  'success'
                )
                .then(_ => {
                  this.fetchSuratJalanKeluar();
                });
            })
            .catch(_ => {
              swal.fire('Ooops', 'failed to delete data', 'error');
            });
        }
      });
  };

  render() {
    let {
      page,
      totalPages,
      jobOrderSwitch,
      job_order,
      dateSwitch,
      start_date,
      end_date,
      usernameSwitch,
      screenWidth
    } = this.state;
    return (
      <>
        <HeroImage title='Surat Jalan' />
        <div className='sj-container mb-3'>
          {localStorage.getItem('admin') == true ? (
            <AddFileCrumb action={this.handleNavigationToCreate} />
          ) : null}
          <h2
            className={screenWidth < 576 ? 'underlined' : 'mt-2 underlined'}
            style={{ color: '#47657A' }}
          >
            SURAT JALAN KELUAR
          </h2>
          <div
            className={
              screenWidth < 576
                ? 'mt-2 mb-1 filter-option-container'
                : 'mt-4 mb-3 filter-option-container'
            }
          >
            <FilterSwitch
              title='Filter job order'
              action={this.handleChangeJobOrderSwitch}
              checked={jobOrderSwitch}
              name='job_order'
              value={job_order}
              type='text'
              valueChanged={this.mapValues}
            />
            <div className={screenWidth < 576 ? 'mt-3' : 'ml-4'}>
              <FilterDate
                checked={dateSwitch}
                action={this.handleChangeDateSwitch}
                start_date={start_date}
                end_date={end_date}
                dateChanged={this.handleDateChanged}
              />
            </div>
            {localStorage.getItem('admin') == true ? (
              <div className={screenWidth < 576 ? 'mt-3' : 'ml-4'}>
                <FilterUser
                  title='Filter user'
                  action={this.handleUserSwitch}
                  checked={usernameSwitch}
                  // joker
                  selectUser={userObject => {
                    this.mapValues({
                      target: {
                        name: 'selectedUser',
                        value: userObject
                      }
                    });
                  }}
                />
              </div>
            ) : null}
          </div>
          <div>
            {this.state.dataSuratMasuk.length == 0
              ? null
              : this.state.dataSuratMasuk.map((data, index) => {
                  return (
                    <SuratJalanKeluarRow
                      key={index}
                      data={data}
                      openPdf={this.openPdf}
                      updateAction={this.updateAction}
                      deleteAction={this.deleteAction}
                      deleteActionSingle={this.deleteActionSingle}
                    />
                  );
                })}
          </div>
          {totalPages != null ? (
            <Paginator
              totalPages={totalPages}
              page={page}
              action={this.handlePageChange}
            />
          ) : null}
        </div>
      </>
    );
  }
}

SuratJalan.contextType = MyContext;

export default withRouter(SuratJalan);
