import {
  Contact,
  Home,
  Services,
  Freight,
  Login,
  Portal,
  SuratJalanMasuk,
  SuratJalanKeluar,
  SuratJalanPortal,
  Create,
  Invoice,
  Stock,
  User
} from '../views';

export default [
  {
    title: 'Home',
    path: '/',
    component: Home,
    isNavbar: true,
    protected: false
  },
  {
    title: 'Services',
    path: '/services',
    component: Services,
    isNavbar: true,
    protected: false
  },
  {
    title: 'Freight Service',
    path: '/freight',
    component: Freight,
    isNavbar: false,
    protected: false
  },
  {
    title: 'Contact Us',
    path: '/contact-us',
    component: Contact,
    isNavbar: true,
    protected: false
  },
  {
    title: 'Login-Portal',
    path: '/login',
    component: Login,
    isNavbar: false,
    protected: false
  },
  {
    title: 'Portal',
    path: '/portal',
    component: Portal,
    isNavbar: false,
    protected: true
  },
  {
    title: 'Surat Jalan Portal',
    path: '/surat-jalan-portal',
    component: SuratJalanPortal,
    isNavbar: false,
    protected: true
  },
  {
    title: 'Surat Jalan Masuk',
    path: '/surat-jalan-masuk',
    component: SuratJalanMasuk,
    isNavbar: false,
    protected: true
  },
  {
    title: 'Surat Jalan Keluar',
    path: '/surat-jalan-keluar',
    component: SuratJalanKeluar,
    isNavbar: false,
    protected: true
  },
  {
    title: 'Invoice',
    path: '/invoice',
    component: Invoice,
    isNavbar: false,
    protected: true
  },
  {
    title: 'Create',
    path: '/create',
    component: Create,
    isNavbar: false,
    protected: true
  },
  {
    title: 'Stock',
    path: '/stock',
    component: Stock,
    isNavbar: false,
    protected: true
  },
  {
    title: 'User',
    path: '/user',
    component: User,
    isNavbar: false,
    protected: true
  }
];
