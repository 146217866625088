import React from 'react';
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker
} from 'react-google-maps';

const Maps = withScriptjs(
  withGoogleMap(props => (
    <GoogleMap 
      defaultZoom={16} 
      defaultCenter={{ lat: -6.150021, lng: 106.764237 }}>
      {props.isMarkerShown && (
        <Marker position={{ lat: -6.150021, lng: 106.764237 }} />
      )}
    </GoogleMap>
  ))
);

export default Maps;
