export default {
  heroTitle: {
    EN: 'Services',
    ID: 'Layanan'
  },
  serviceTitle: {
    EN: 'Our Services',
    ID: 'Layanan Kami'
  },
  servicesSubheading: {
    EN: 'We offer a range of different services to cater your every need',
    ID:
      'Kami menawarkan berbagai layanan yang berbeda untuk memenuhi setiap kebutuhan Anda'
  },
  warehouse: {
    EN: 'Fullfilment Storage',
    ID: 'Gudang Umum'
  },
  warehouseText: {
    EN:
      'ATS warehouse has a floor space of 2100 m2. Not only for storing, we are also capable of receiving and delivering the goods.',
    ID:
      'ATS memiliki gudang umum seluas 2100 m2. Selain penyimpanan, layanan kami dilengkapi dengan penerimaan dan pengiriman barang.'
  },
  freight: {
    EN: 'Freight Forwarder',
    ID: 'Freight Forwarder'
  },
  freightText: {
    EN:
      'An door-to-door delivery process for individual or corporate interest from supplier to any designated place. We are capable of shipping through air, sea, and land that fits your requirements.',
    ID:
      'Proses tuntas dalam pengiriman barang untuk individual maupun perusahaan dari suplier ke tempat tujuan. Kami menyediakan pilihan pengiriman melalui udara, laut, dan darat yang disesuaikan dengan kebutuhan Anda.'
  },
  clearance: {
    EN: 'Clearance Service',
    ID: 'Clearance Service'
  },
  clearanceText: {
    EN:
      'This service covers the operational process of custom duty that suits your business. We provide information and management about all documentation and cost that are needed to ensure the goods to be delivered as expected.',
    ID:
      'Layanan untuk mengatasi proses operasional bea cukai yang paling sesuai untuk mendukung bisnis Anda. Kami menyediakan informasi dan pengelolaan dokumen juga harga yang dibutuhkan untuk memastikan barang Anda sampai sesuai harapan.'
  },
  learnMore: {
    EN: 'Lean More',
    ID: 'Info Lebih'
  },
  advantageTitle: {
    EN: 'Advantages on Our Services',
    ID: 'Keuntungan dari Service Kami'
  },
  advantageText: {
    EN:
      'We believe that efficiency and effectiveness are one of the main keys for a healthy business. Therefore, ATS is not only a third-party logistics company, but we aim to be a reliable partner for expanding your business. Our range of services will cater based on these goals we strive for in order to meet our customers business needs',
    ID:
      'Kami percaya bahwa efisiensi dan efektifitas adalah salah satu kunci utama untuk bisnis yang sehat. Karena itu, ATS hadir bukan hanya sebagai perusahaan logistik tapi juga partner Anda dalam mengembangkan bisnis. Maka berbagai layanan yang berbeda dibentuk agar memenuhi tujuan long-term kami dan setiap kebutuhan bisnis anda'
  }
};
