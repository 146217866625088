import React from 'react';
import './style.css';

const GradientContainer = props => {
  return (
    <div
      onClick={props.action}
      className='default-container center-item'
      style={{ marginTop: '18px' }}
    >
      <p className='block-white'>{props.text ?? 'EXECUTE'}</p>
    </div>
  );
};

export default GradientContainer;
