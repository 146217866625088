import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import './style.css';
import Switch from 'react-switch';
import { Form, Modal, ListGroup, FormControl, Button } from 'react-bootstrap';
import Paginator from '../../components/Pagination';
import { url } from '../../utils/const';
import swal from 'sweetalert2';
import axios from 'axios';

class FilterUser extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      option: [],
      show: false,
      page: 1,
      per_page: 10,
      totalPages: null,

      searchValue: '',
      doge: 'wooof',
      userObject: {
        username: null,
        id: null
      }
    };
  }

  componentDidMount() {
    this.fetchAllUsers();
  }

  fetchAllUsers = () => {
    let { page, per_page, searchValue, userObject } = this.state;
    let { selectUser } = this.props;

    let token = localStorage.getItem('token');

    axios
      .get(url + 'users', {
        headers: {
          Accept: 'application/json',
          Authorization: `Bearer ${token}`,
          page,
          per_page
        },
        params: {
          username: searchValue
        }
      })
      .then(response => {
        let userArr = response.data.result.data;
        if (userArr.length > 0 && userObject.username == null) {
          this.setState(
            {
              ...this.state,
              option: userArr,
              totalPages: response.data.result.total_pages,
              userObject: userArr[0]
            },
            () => {
              selectUser(userArr[0]);
            }
          );
        } else {
          this.setState({
            ...this.state,
            option: userArr,
            totalPages: response.data.result.total_pages
          });
        }
      })
      .catch(err => {
        swal.fire(
          'fetch error',
          'failed to fetch users, try refreshing the page',
          JSON.stringify(err)
        );
      });
  };

  handleShow = () => {
    this.setState({
      ...this.state,
      show: true
    });
  };

  handleClose = () => {
    this.setState({
      ...this.state,
      show: false
    });
  };

  mapValues = e => {
    this.setState({
      ...this.state,
      [e.target.name]: e.target.value
    });
  };

  handlePageChange = page => {
    this.setState(
      {
        ...this.state,
        page
      },
      () => {
        this.fetchAllUsers();
      }
    );
  };

  render() {
    let { action, checked, title, selectUser } = this.props;
    let {
      show,
      searchValue,
      option,
      userObject,
      page,
      totalPages
    } = this.state;

    return (
      <div className='filter-container'>
        <div className='switch-container'>
          <p className='filter-text'>{title}</p>
          <Switch
            className='level-switch'
            onChange={action}
            checked={checked}
            height={21}
            width={47}
          />
        </div>
        <Form.Control
          className={'filter-form-container level-filter-container'}
          type='text'
          placeholder={'Select User'}
          name={'userObject'}
          value={userObject.username != null ? userObject.username : ''}
          readOnly
          onClick={() => {
            this.handleShow();
          }}
        />
        <Modal show={show} centered onHide={this.handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Select A User</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form inline className='mb-3'>
              <FormControl
                type='text'
                placeholder={'Search User'}
                value={searchValue}
                name='searchValue'
                onChange={this.mapValues}
                className='mr-sm-2'
              />
              <Button
                onClick={() => {
                  this.fetchAllUsers();
                }}
                variant='outline-success'
              >
                Search
              </Button>
            </Form>
            {option.map((dogObject, index) => {
              return (
                <ListGroup.Item
                  key={index}
                  onClick={() => {
                    let userObject = {
                      username: dogObject.username,
                      id: dogObject.id
                    };
                    this.setState(
                      {
                        ...this.state,
                        userObject: userObject
                      },
                      () => {
                        selectUser(userObject);
                        this.handleClose();
                      }
                    );
                  }}
                >
                  <p className='matt' style={{ margin: '0px' }}>
                    {dogObject.username}
                  </p>
                </ListGroup.Item>
              );
            })}
            {totalPages != null ? (
              <div style={{ margin: '0 auto', width: '40%' }}>
                <Paginator
                  totalPages={totalPages}
                  page={page}
                  action={this.handlePageChange}
                />
              </div>
            ) : null}
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

export default withRouter(FilterUser);
