import React from 'react';
import './style.css';
import HeroImage from '../../components/HeroImage';
import MyContext from '../../middleware/context';
import { withRouter } from 'react-router-dom';
import imageObject from '../../utils/images';
import { Container } from 'react-bootstrap';

class SuratJalanPortal extends React.Component {
  render() {
    return (
      <div className='mt-5 mb-5'>
        <HeroImage title='Surat Jalan Portal' />
        <Container>
          <div className='sjp-container'>
            <div className='sjp-item-wrapper'>
              <img
                onClick={() => {
                  // '/surat-jalan-masuk'
                  this.props.history.push('surat-jalan-masuk');
                }}
                src={imageObject.suratJalanMasuk}
                height='100%'
                width='100%'
              />
              <div className='mt-3 gradient-container'>
                <p style={{ marginTop: '13px' }}>SURAT JALAN MASUK</p>
              </div>
            </div>
            <div className='sjp-item-wrapper'>
              <img
                onClick={() => {
                  '/surat-jalan-keluar';
                  this.props.history.push('surat-jalan-keluar');
                }}
                src={imageObject.suratJalanKeluar}
                height='100%'
                width='100%'
              />
              <div className='mt-3 gradient-container'>
                <p style={{ marginTop: '13px' }}>SURAT JALAN KELUAR</p>
              </div>
            </div>
          </div>
        </Container>
      </div>
    );
  }
}

SuratJalanPortal.contextType = MyContext;

export default withRouter(SuratJalanPortal);
