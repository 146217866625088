import React, { useEffect } from 'react';
import { Container } from 'react-bootstrap';
import HeroImage from '../../components/HeroImage';
import ServicesList from './components/ServicesList';
import dictionary from './words';
import MyContext from '../../middleware/context';
import imageObject from '../../utils/images';

const Services = props => {
  const { title } = props;

  useEffect(() => {
    document.title = `${title} | ATS`;
  }, []);

  return (
      <MyContext.Consumer>
      {(context) => {
        return (
          <>
          <HeroImage image={imageObject.serviceHeader} title=""/>
          <Container className='page-container'>
            <div className='mb-3'>
              <h2>{dictionary.serviceTitle[context.state.currentLanguage]}</h2>
              <p><b>{dictionary.servicesSubheading[context.state.currentLanguage]}</b></p>
            </div>
            <div className='mb-5'>
              <ServicesList dictionary={dictionary} context={context}/>
            </div>
            <div className='mb-50'>
              <h2>{dictionary.advantageTitle[context.state.currentLanguage]}</h2>
              <p><b>Let’s Move Together and Move Forward</b></p>
              <p>{dictionary.advantageText[context.state.currentLanguage]}</p>
            </div>
          </Container>
          </>
          ) 
      }}
      </MyContext.Consumer>
  );
};

export default Services;
