import React, { useState } from 'react';
import '../style.css';
import imageObject from '../../../utils/images';
import commonObject from '../../../utils/common.js';

const StockRow = props => {
  let {
    job_order,
    no_kontainer,
    updated_at,
    excel_url,
    description,
    stock,
    update_admin
  } = props.data;

  const [fullDesc, setShowDesc] = useState(false);

  const toggleDesc = () => {
    setShowDesc(!fullDesc);
  };

  return (
    <div className='stk-row-container'>
      <div className='stk-row-part borderRight job-order-column'>
        <p className='truncate' style={{ marginTop: '10px', width: '20' }}>
          {`Job Order:${job_order}`}
        </p>
      </div>
      <div className='stk-row-part borderRight no-kontainer-column'>
        <p className='truncate' style={{ marginTop: '10px', width: '20' }}>
          {`No Kontainer:${no_kontainer}`}
        </p>
      </div>
      <div className='stk-row-part borderRight stock-masuk-column'>
        <p className='truncate' style={{ marginTop: '10px', width: '20' }}>
          {`Stock Sisa:${stock}`}
        </p>
      </div>
      <div
        className={
          fullDesc
            ? 'stk-row-part borderRight free-description-column'
            : 'stk-row-part borderRight description-column'
        }
        onClick={() => {
          toggleDesc();
        }}
      >
        <p className='truncate' style={{ marginTop: '10px', width: '20' }}>
          {`Desc: ${description}`}
        </p>
      </div>
      <div className='stk-row-part borderRight last-updated-column'>
        <p className='' style={{ marginTop: '10px', width: '20' }}>
          {`Uploaded At: ${commonObject.parseDateLocal(
            updated_at
          )} by: ${update_admin}`}
        </p>
      </div>
      <div
        onClick={() => {
          props.openPdf(excel_url);
        }}
        className='stk-row-part'
      >
        <p className='' style={{ marginTop: '10px' }}>
          {`Open PDF:`}
        </p>
        <img
          src={imageObject.excel}
          style={{ marginTop: '12px', marginLeft: '5px' }}
          width={27}
          height={27}
        />
      </div>
      {localStorage.getItem('admin') == true ? (
        <>
          <div
            className='stk-row-part borderLeft'
            onClick={() => {
              let dataObject = {
                ...props.data,
                selectedUser: {
                  id: props.data.user_id,
                  username: props.data.username
                }
              };
              props.updateAction(dataObject);
            }}
          >
            <p
              className=''
              style={{ marginTop: '10px', width: '20', color: '#2193b0' }}
            >
              UPDATE
            </p>
          </div>
          <div
            className='stk-row-part borderLeft'
            onClick={() => {
              props.deleteAction(props.data);
            }}
          >
            <p
              className=''
              style={{ marginTop: '10px', width: '20', color: '#b92b27' }}
            >
              DELETE
            </p>
          </div>
        </>
      ) : null}
    </div>
  );
};

export default StockRow;
