import React, { useState } from 'react';
import '../style.css';
import commonObject from '../../../utils/common.js';

const UserRow = props => {
  let { id, updated_at, username } = props.data;

  const [fullUser, setShowUser] = useState(true);

  const toggleUser = () => {
    setShowUser(!fullUser);
  };

  const renderUpdateDelete = () => {
    let userObject = props.data;
    if (userObject.admin == false) {
      return (
        <>
          <div
            className='stk-row-part borderLeft'
            // user = 3
            onClick={() => {
              props.updateAction(props.data);
            }}
          >
            <p
              className=''
              style={{ marginTop: '10px', width: '20', color: '#2193b0' }}
            >
              UPDATE
            </p>
          </div>
          <div
            className='stk-row-part borderLeft'
            onClick={() => {
              props.deleteAction(props.data);
            }}
          >
            <p
              className=''
              style={{ marginTop: '10px', width: '20', color: '#b92b27' }}
            >
              DELETE
            </p>
          </div>
        </>
      );
    } else if (localStorage.getItem('id') == 3) {
      return (
        <>
          <div
            className='stk-row-part borderLeft'
            // user = 3
            onClick={() => {
              props.updateAction(props.data);
            }}
          >
            <p
              className=''
              style={{ marginTop: '10px', width: '20', color: '#2193b0' }}
            >
              UPDATE
            </p>
          </div>
          {userObject.id == 3 ? null : (
            <div
              className='stk-row-part borderLeft'
              onClick={() => {
                props.deleteAction(props.data);
              }}
            >
              <p
                className=''
                style={{ marginTop: '10px', width: '20', color: '#b92b27' }}
              >
                DELETE
              </p>
            </div>
          )}
        </>
      );
    } else {
      return null;
      // return userObject.id == localStorage.getItem('id') ? (
      //   <div
      //     className='stk-row-part borderLeft'
      //     // user = 3
      //     onClick={() => {
      //       props.updateAction(props.data);
      //     }}
      //   >
      //     <p
      //       className=''
      //       style={{ marginTop: '10px', width: '20', color: '#2193b0' }}
      //     >
      //       UPDATE
      //     </p>
      //   </div>
      // ) : null;
    }
  };

  return (
    <div className='sj-row-blue' key={id}>
      <div className='sj-row-part borderRight'>
        <p className='truncate' style={{ marginTop: '10px' }}>
          {`Updated At: ${commonObject.parseDateLocal(updated_at)}`}
        </p>
      </div>
      <div
        className={
          fullUser
            ? 'sj-row-part users-column'
            : 'sj-row-part free-users-column'
        }
        onClick={() => {
          toggleUser();
        }}
      >
        <p className='truncate' style={{ marginTop: '10px' }}>
          {`Username: ${username}`}
        </p>
      </div>
      {localStorage.getItem('admin') == true ? renderUpdateDelete() : null}
    </div>
  );
};

export default UserRow;
