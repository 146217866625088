import React from 'react';
import { Breadcrumb } from 'react-bootstrap';

const AddFileCrumb = props => {
  let { action } = props;

  return (
    <Breadcrumb className='mt-4'>
      <Breadcrumb.Item
        onClick={() => {
          action(0);
        }}
      >
        Add Surat Jalan Masuk
      </Breadcrumb.Item>
      <Breadcrumb.Item
        onClick={() => {
          action(1);
        }}
      >
        Add Surat Jalan Keluar
      </Breadcrumb.Item>
      <Breadcrumb.Item
        onClick={() => {
          action(2);
        }}
      >
        Add Stock
      </Breadcrumb.Item>
      <Breadcrumb.Item
        onClick={() => {
          action(3);
        }}
      >
        Add Invoice
      </Breadcrumb.Item>
      <Breadcrumb.Item
        onClick={() => {
          action(4);
        }}
      >
        Add User
      </Breadcrumb.Item>
      <Breadcrumb.Item
        onClick={() => {
          action(5);
        }}
      >
        Add Admin
      </Breadcrumb.Item>
    </Breadcrumb>
  );
};

export default AddFileCrumb;
