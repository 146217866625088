import React, { Component } from 'react';
import './style.css';
import { withRouter } from 'react-router-dom';
import HeroImage from '../../components/HeroImage';
import axios from 'axios';
import { url } from '../../utils/const';
import swal from 'sweetalert2';
import Paginator from '../../components/Pagination';
import AddFileCrumb from '../../components/AddFileCrumb';
import UserRow from './components/userRow';
import FilterSwitch from '../../components/FilterSwitch';

class User extends Component {
  constructor(props) {
    super(props);
    this.state = {
      usersData: [],
      per_page: 10,
      page: 1,
      totalPages: null,
      usernameSwitch: '',
      usernameSearch: ''
    };
  }

  componentDidMount() {
    this.fetchUsersData();
  }

  fetchUsersData = () => {
    let { page, per_page, usernameSearch, usernameSwitch } = this.state;
    axios
      .get(url + 'users', {
        headers: {
          Accept: 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`,
          page,
          per_page
        },
        params: {
          username: usernameSwitch == true ? usernameSearch : ''
        }
      })
      .then(response => {
        let { data, total_pages } = response.data.result;
        this.setState({
          ...this.state,
          usersData: data,
          totalPages: total_pages
        });
      })
      .catch(err => {
        swal.fire('Ooops', 'failed to get users data', 'error');
      });
  };

  handleUserSwitch = checked => {
    this.setState(
      {
        ...this.state,
        usernameSwitch: checked
      },
      () => {
        this.fetchUsersData();
      }
    );
  };

  handlePageChange = page => {
    if (page != this.state.page) {
      this.setState(
        {
          ...this.state,
          page: page
        },
        () => {
          this.fetchUsersData();
        }
      );
    }
  };

  updateAction = data => {
    data.password = 'ENCRYPTED';
    this.props.history.push({
      pathname: '/create',
      state: {
        type: 'users',
        isUpdate: true,
        updateData: data
      }
    });
  };

  deleteAction = data => {
    swal
      .fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
      })
      .then(result => {
        if (result.value) {
          let headers = {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
            Accept: `application/json`
          };
          axios
            .delete(url + 'users' + `/${data.id}`, {
              headers
            })
            .then(_ => {
              swal
                .fire(
                  'Deleted!',
                  `delete file with id: ${data.id} has been deleted.`,
                  'success'
                )
                .then(_ => {
                  this.fetchUsersData();
                });
            })
            .catch(_ => {
              swal.fire('Ooops', 'failed to delete data', 'error');
            });
        }
      });
  };

  handleNavigationToCreate = page => {
    let pageArr = [
      'suratJalan/masuk',
      'suratJalan/keluar',
      'stock',
      'invoice',
      'users',
      'users/admin'
    ];
    this.props.history.push({
      pathname: '/create',
      state: { type: pageArr[page] }
    });
  };

  mapValues = e => {
    this.setState(
      {
        ...this.state,
        [e.target.name]: e.target.value
      },
      () => {}
    );
  };

  render() {
    let {
      usersData,
      totalPages,
      page,
      usernameSwitch,
      usernameSearch
    } = this.state;
    return (
      <>
        <HeroImage title='USER' />
        <div className='sj-container mb-3'>
          {localStorage.getItem('admin') == true ? (
            <AddFileCrumb action={this.handleNavigationToCreate} />
          ) : null}
          <h2 className={'mt-3 underlined'} style={{ color: '#47657A' }}>
            USER
          </h2>
          <div
            className='filter-option-container mb-2 mt-2'
            style={{ justifyContent: 'center' }}
          >
            <FilterSwitch
              title='Filter user'
              action={this.handleUserSwitch}
              checked={usernameSwitch}
              name='usernameSearch'
              value={usernameSearch}
              placeholder='Search User'
              valueChanged={this.mapValues}
              type='text'
            />
          </div>
          {usersData.length == 0
            ? null
            : usersData.map((data, index) => {
                return (
                  <UserRow
                    key={index}
                    data={data}
                    updateAction={this.updateAction}
                    deleteAction={this.deleteAction}
                  />
                );
              })}
          {totalPages != null ? (
            <Paginator
              totalPages={totalPages}
              page={page}
              action={this.handlePageChange}
            />
          ) : null}
        </div>
      </>
    );
  }
}

export default withRouter(User);
