import React from 'react';

import 'react-responsive-carousel/lib/styles/carousel.min.css';
import './style.css';

import { Carousel } from 'react-responsive-carousel';

const ImageCarousel = props => {
  const { contents } = props;

  return (
    <Carousel autoPlay infiniteLoop transitionTime={500} showThumbs={false}>
      {contents.map((content, index) => (
        <div key={index}>
          <img src={content.image} alt={content.title} />
          <div className='carousel-content'>
            <h1 className='font-bold lg-responsive'>{content.title}</h1>
            <p className='md-responsive'>{content.subtitle}</p>
            {content.action && content.action}
          </div>
        </div>
      ))}
    </Carousel>
  );
};

export default ImageCarousel;
