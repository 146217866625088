import React from 'react';
import '../style.css';
import imageObject from '../../../utils/images';
import commonObject from '../../../utils/common.js';

const SuratJalanRow = props => {
  let {
    id,
    job_order,
    updated_at,
    pdf_url,
    update_admin,
    no_kontainer,
    stock_masuk
  } = props.data;
  return (
    <div className='sj-row' key={id}>
      <div className='sj-row-part borderRight job-order-column'>
        <p className='truncate' style={{ marginTop: '10px' }}>
          {`JOB ORDER:${job_order}`}
        </p>
      </div>
      <div className='sj-row-part borderRight no-kontainer-column'>
        <p className='truncate' style={{ marginTop: '10px' }}>
          {`No Kontainer:${no_kontainer}`}
        </p>
      </div>
      <div className='sj-row-part borderRight stock-masuk-column'>
        <p className='truncate' style={{ marginTop: '10px' }}>
          {`Stock Masuk:${stock_masuk}`}
        </p>
      </div>
      <div className='sj-row-part borderRight last-updated-column'>
        <p style={{ marginTop: '10px' }}>
          {`Uploaded At: ${commonObject.parseDateLocal(
            updated_at
          )} by: ${update_admin}`}
        </p>
      </div>
      <div
        onClick={() => {
          props.openPdf(pdf_url);
        }}
        className='sj-row-part'
      >
        <p className='' style={{ marginTop: '10px' }}>
          {`Open PDF:`}
        </p>
        <img
          src={imageObject.pdf}
          style={{ marginTop: '10px', marginLeft: '5px' }}
          width={22}
          height={22}
        />
      </div>
      {localStorage.getItem('admin') == true ? (
        <>
          <div
            className='stk-row-part borderLeft'
            onClick={() => {
              let dataObject = {
                ...props.data,
                selectedUser: {
                  id: props.data.user_id,
                  username: props.data.username
                }
              };
              props.updateAction(dataObject);
            }}
          >
            <p
              className=''
              style={{ marginTop: '10px', width: '20', color: '#2193b0' }}
            >
              UPDATE
            </p>
          </div>
          <div
            className='stk-row-part borderLeft'
            onClick={() => {
              props.deleteAction(props.data);
            }}
          >
            <p
              className=''
              style={{ marginTop: '10px', width: '20', color: '#b92b27' }}
            >
              DELETE
            </p>
          </div>
        </>
      ) : null}
    </div>
  );
};

export default SuratJalanRow;
