import React from 'react';
import { Breadcrumb } from 'react-bootstrap';

const NavigationCrumb = props => {
  let { action } = props;

  return (
    <Breadcrumb className='mt-4'>
      <Breadcrumb.Item
        onClick={() => {
          action(0);
        }}
      >
        Portal
      </Breadcrumb.Item>
      <Breadcrumb.Item
        onClick={() => {
          action(1);
        }}
      >
        Surat Jalan Masuk
      </Breadcrumb.Item>
      <Breadcrumb.Item
        onClick={() => {
          action(2);
        }}
      >
        Surat Jalan Keluar
      </Breadcrumb.Item>
      <Breadcrumb.Item
        onClick={() => {
          action(3);
        }}
      >
        Stock
      </Breadcrumb.Item>
      <Breadcrumb.Item
        onClick={() => {
          action(4);
        }}
      >
        Invoice
      </Breadcrumb.Item>
      {localStorage.getItem('admin') == true ? (
        <Breadcrumb.Item
          onClick={() => {
            action(5);
          }}
        >
          Users
        </Breadcrumb.Item>
      ) : null}
    </Breadcrumb>
  );
};

export default NavigationCrumb;
