import React, { useState } from 'react';
import { Form, Col } from 'react-bootstrap';
import '../style.css';
import GradientContainer from '../../../components/GradientContainer';

const LoginForm = props => {
  const [form, setState] = useState({
    username: '',
    password: ''
  });

  const updateField = e => {
    setState({
      ...form,
      [e.target.name]: e.target.value
    });
  };

  return (
    <>
      <div className='matt-border center-item mt-5'>
        <input
          className='hode-border login-input'
          type='text'
          placeholder='Username'
          value={form.username}
          name='username'
          onChange={updateField}
        />
      </div>
      <div className='matt-border center-item' style={{ marginTop: '19px' }}>
        <input
          className='hode-border login-input'
          type='password'
          placeholder='Password'
          value={form.password}
          name='password'
          onChange={updateField}
        />
      </div>
      <GradientContainer
        text='LOGIN'
        action={() => {
          props.onSubmit(form);
        }}
      />
    </>
  );
};

export default LoginForm;
