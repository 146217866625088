import mainLogo from '../assets/logo-normal.png';
import freightForward from '../assets/icon-1.png';
import fulfilmentStorage from '../assets/icon-2.png';
import customClearance from '../assets/icon-3.png';
import homeImageOne from '../assets/warehouse-image.jpeg';
import homeImageTwo from '../assets/home-image-2.jpg';
import homeImageThree from '../assets/home-image-3.jpg';
import bannerImageOne from '../assets/container-dock.jpeg';
import bannerImageTwo from '../assets/loading-dock.jpeg';
import heroImageDefault from '../assets/port-dock.jpeg';
import mainHeader from '../assets/carrier-image.png';
import serviceHeader from '../assets/service-header.png';
import contactHeader from '../assets/contact-header.png';
import fulfilmentStorageHeader from '../assets/fulfilment-storage-header.png';
import freightForwardHeader from '../assets/freight-forward-header.png';
import clearanceHeader from '../assets/clearance-header.png';
import whatsAppImage from '../assets/wa-image.svg';
import warehouseFront from '../assets/warehouse-front.jpeg';
import warehouseInside from '../assets/warehouse-inside.jpeg';
import warehouseLanding from '../assets/warehouse-landing.jpeg';
import stockCircle from '../assets/boxes.png';
import invoiceCircle from '../assets/invoice.png';
import suratJalanCircle from '../assets/freight.png';
import suratJalanMasuk from '../assets/sjk-circle.png';
import suratJalanKeluar from '../assets/sjm-circle.png';
import folder from '../assets/folder.png';
import excel from '../assets/excel.png';
import upload from '../assets/upload.png';
import pdf from '../assets/pdf.png';
import userCircle from '../assets/user.png';
import homeImageAncolOne from '../assets/web-ats-01.jpg';
import homeImageAncolTwo from '../assets/web-ats-02.jpg';
import homeImageAncolThree from '../assets/web-ats-03.jpg';
import homeImageAncolFour from '../assets/web-ats-04.jpg';
import homeImageAncolFive from '../assets/web-ats-05.jpg';
import homeImageAncolSix from '../assets/web-ats-06.jpg';
import homeImageAncolSeven from '../assets/web-ats-07.jpg';
import homeImageAncolEight from '../assets/web-ats-08.jpeg';
import homeImageAncolNine from '../assets/web-ats-09.jpeg';
import homeImageAncolTen from '../assets/web-ats-10.jpeg';
import homeImageAncolEleven from '../assets/web-ats-11.jpeg';

// Reviews Section
import userOne from '../assets/user1.png';
import userTwo from '../assets/user2.png';
import userThree from '../assets/user3.png';
import companyOne from '../assets/company1.png';
import companyTwo from '../assets/company2.png';
import companyThree from '../assets/company3.png';

// Data Section
import iconOne from '../assets/icon-4.png';
import iconTwo from '../assets/icon-5.png';

let imageObject = {
  mainLogo,
  freightForward,
  fulfilmentStorage,
  customClearance,
  homeImageOne,
  homeImageTwo,
  homeImageThree,
  bannerImageOne,
  bannerImageTwo,
  heroImageDefault,
  mainHeader,
  serviceHeader,
  contactHeader,
  fulfilmentStorageHeader,
  freightForwardHeader,
  clearanceHeader,
  whatsAppImage,
  warehouseFront,
  warehouseInside,
  warehouseLanding,
  stockCircle,
  invoiceCircle,
  suratJalanCircle,
  userCircle,
  suratJalanMasuk,
  suratJalanKeluar,
  folder,
  excel,
  upload,
  pdf,
  homeImageAncolOne,
  homeImageAncolTwo,
  homeImageAncolThree,
  homeImageAncolFour,
  homeImageAncolFive,
  homeImageAncolSix,
  homeImageAncolSeven,
  homeImageAncolEight,
  homeImageAncolNine,
  homeImageAncolTen,
  homeImageAncolEleven,
  userOne,
  userTwo,
  userThree,
  companyOne,
  companyTwo,
  companyThree,
  iconOne,
  iconTwo
};

export default imageObject;
