import React from 'react';
import {
  Container,
  Col,
  Row,
  Button,
  FormControl,
  InputGroup,
  Image
} from 'react-bootstrap';
import imageObject from '../../utils/images';

import './style.css';

const Footer = () => {
  const openWA = () => {
    const win = window.open('https://wa.me/6281265651212', '_blank');
    win.focus();
  };

  return (
    <footer>
      <Container className='py-4'>
        <Row className='mb-4'>
          <Col>
            <p className='font-bold color-white mb-2'>
              Let’s Move Together and Move Forward
            </p>
            <p className='color-white mb-0'>Get in touch with us :</p>
            <p className='color-white mb-0'>Landline: +(021) 22301212</p>
            <div className='contact-div' onClick={openWA}>
              <p className='color-white mb-0'>
                Contact Person: +62 81265651212
              </p>
              <span>Click for whatsapp: </span>
              <Image
                className='testing-center'
                src={imageObject.whatsAppImage}
              />
            </div>
            <p className='color-white mb-0'>
              Gudang Cengkareng: Pergudangan Kedaung, Jl. Peternakan I blok B4
              RT 006 RW 001, Kedaung Kali Angke, Cengkareng, West Jakarta, 11710
            </p>
            <p className='color-white mb-0'>
              Gudang Ancol: Jl Ancol Barat 6 no.3, Ancol Pademangan, Jakarta
              Utara
            </p>
            <p className='color-white mb-0'>
              Gudang Pluit: JL Pluit Raya kav. 70, Penjaringan, Jakarta Utara
            </p>
          </Col>
        </Row>
        <Row>
          <Col>
            <p className='color-white mb-1'>
              Copyright © 2020 ATS. All rights reserved.
            </p>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
