import React from 'react';
import { Button, Container, Image } from 'react-bootstrap';
import Collapsible from 'react-collapsible';
import { withRouter } from 'react-router-dom';
import HeroImage from '../../components/HeroImage';
import imageObject from '../../utils/images';
import Testimonials from './components/Testimonials';
import LocationImage from './components/LocationImage';
import MyContext from '../../middleware/context';
import './style.css';
import dictionaryObjc from './words.js';

const countryList = [{
  title: "China",
  image: imageObject.bannerImageOne
},{
  title: "Africa",
  image: imageObject.bannerImageTwo
},{
  title: "India",
  image: imageObject.bannerImageOne
},{
  title: "Korea",
  image: imageObject.bannerImageTwo
}]


const Freight = props => {
  const { history } = props;
  const { content } = props.location.state
  const dictionary = dictionaryObjc[props.location.state.content.itemKey]
  
  return (
    <MyContext.Consumer>
      {(context) => {
        return (
          <>
            <HeroImage title="" image={ content.image }/>
              <Container className='mt-5'>
                <div className='text-left'>
                  <h2>{dictionary.title[context.state.currentLanguage]}</h2>
                  <p>
                    {dictionary.subtext[context.state.currentLanguage]}
                  </p>
                </div>
                <div className="my-5">
                  <h2 className="text-left mt-3">{dictionary.benefitTitle[context.state.currentLanguage]}</h2>
                  {dictionary.benefitArr[context.state.currentLanguage].map((benefit, key) => {
                    return (
                      <React.Fragment>
                        <h4>{`${key + 1}. ${benefit.heading}`}</h4>
                        <p>{benefit.text}</p>
                      </React.Fragment>
                    )
                  })}
                </div>
              </Container>
              <div className='bg-grey mb-5'>
                <Container className='py-5'>
                  <h2>{dictionaryObjc.common.questionTitle[context.state.currentLanguage]}</h2>
                  {dictionary.collapsibleContent[context.state.currentLanguage].map((item, index) => (
                    <Collapsible trigger={item.question} transitionTime={200} key={index}>
                      <p>{item.answer}</p>
                    </Collapsible>
                  ))}
                </Container>
              </div>
              {/* <div className='my-5 px-5'>
                <h2 className='text-center mb-3'>
                  We provide range of freight and cargo services to Indonesia from:
                </h2>
              </div>
              <div className='bg-grey locations d-flex'>
                {countryList.map((country, index) => {
                  return (
                    <LocationImage key={index} country={country.title} image={country.image}/>
                  )
                })}
              </div> */}
              {/* <div>
                <Testimonials />
              </div> */}
              <div className='text-center mt-5 mb-5'>
                <Button
                  className='bg-blue'
                  size='md'
                  onClick={() => history.push('/contact-us')}
                >
                  Get In Touch
                </Button>
              </div>
            </>)
      }}
    </MyContext.Consumer>
  );
};

export default withRouter(Freight);
