import React from 'react';
import '../style.css';

const FilePicker = props => {
  let { dataArray, action } = props;
  return (
    <div className='picker-container'>
      {dataArray.map((object, index) => {
        return (
          <div className='portal-item-container' key={index}>
            <img
              style={{
                width: '100%',
                height: '100%'
              }}
              onClick={() => {
                action(index);
              }}
              src={object.image}
            />
            <div className='gradient-container'>
              <p style={{ marginTop: '13px' }}>{object.text}</p>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default FilePicker;
