import React from 'react';
import { Redirect } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import swal from 'sweetalert2';

import HeroImage from '../../components/HeroImage';
import axios from 'axios';
import { withRouter } from 'react-router-dom';
import MyContext from '../../middleware/context';
import LoginLogo from './components/LoginLogo.js';
import LoginForm from './components/LoginForm';
import dictionary from './words';
import { url } from '../../utils/const';
import './style.css';

const Login = props => {
  const openWA = () => {
    const win = window.open('https://wa.me/6281265651212', '_blank');
    win.focus();
  };

  return (
    <MyContext.Consumer>
      {context => {
        const { currentLanguage, credentials } = context.state;
        return (
          <>
            <HeroImage title='Login Portal' />
            <Container className='page-container'>
              <div className='mb-4'>
                <LoginLogo />
                <p className='push-top'>
                  {dictionary.tagline[context.state.currentLanguage]}
                </p>
                <LoginForm
                  onSubmit={data => {
                    axios
                      .post(url + 'users/login', data, {
                        headers: {
                          Accept: 'application/json'
                        }
                      })
                      .then(response => {
                        let { admin, id, token, username } = response.data;
                        localStorage.setItem('admin', admin);
                        localStorage.setItem('id', id);
                        localStorage.setItem('token', token);
                        localStorage.setItem('username', username);
                        context.setCredentials(response.data);
                        swal.fire(
                          'success',
                          `you are logged as ${username}`,
                          'success'
                        );
                      })
                      .catch(err => {
                        if (err.response) {
                          swal.fire(
                            'Ooops',
                            `${err.response.data.result.message}`,
                            'error'
                          );
                        } else {
                          swal.fire('Ooops', 'Something went wrong', 'error');
                        }
                      });
                  }}
                />
                <p className='mt-3 matt' onClick={openWA}>
                  {dictionary.subheading[context.state.currentLanguage]}
                </p>
              </div>
              {/* { credentials ==  null ? null : <Redirect to="/portal" /> } */}
              {localStorage.getItem('token') == null ||
              localStorage.getItem('token') == undefined ? null : (
                <Redirect to='/portal' />
              )}
            </Container>
          </>
        );
      }}
    </MyContext.Consumer>
  );
};

export default withRouter(Login);
