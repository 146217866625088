import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from 'react-router-dom';

import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';

import Navigation from './components/Navigation';
import Footer from './components/Footer';

import routes from './routes';
import * as serviceWorker from './serviceWorker';
import { MyProvider } from './middleware/context';
import ScrollToTop from './middleware/scrollToTop';

const checkAuth = () => {
  const token = localStorage.getItem('token');
  if (token == null || token == undefined) {
    return false;
  } else {
    return true;
  }
};

const AuthRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      checkAuth() ? (
        <Component {...props} />
      ) : (
        <Redirect to={{ pathname: '/login' }} />
      )
    }
  />
);

ReactDOM.render(
  <Router>
    <ScrollToTop>
      <MyProvider>
        <Navigation routes={routes} />
        <Switch>
          {routes.map((prop, key) => {
            if (prop.protected == false) {
              return (
                <Route exact path={prop.path} key={key}>
                  <prop.component {...prop} />
                </Route>
              );
            } else {
              return (
                <AuthRoute exact path={prop.path} component={prop.component} />
              );
            }
          })}
        </Switch>
      </MyProvider>
      <Footer />
    </ScrollToTop>
  </Router>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
