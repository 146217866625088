export default {
  tagline: {
    EN:
      'A one stop solution to track your documents in Adhirajasa Trans Sejahtera',
    ID:
      'Solusi satu atap untuk melacak dokumen anda di Adhirajasa Trans Sejahter'
  },
  subheading: {
    EN: "Don't have an account? Click here to get your account",
    ID: 'Belum punya akun? Klik di sini untuk mendapatkan akun Anda'
  }
};
