import React, { useState } from 'react';
import '../style.css';
import imageObject from '../../../utils/images';
import commonObject from '../../../utils/common.js';

const InvoiceRow = props => {
  let { id, updated_at, pdf_url, month, update_admin } = props.data;

  return (
    <div className='sj-row-blue' key={id}>
      <div className='sj-row-part borderRight month-column'>
        <p className='truncate' style={{ marginTop: '12px' }}>
          {`Month: ${commonObject.parseMonth(month)}`}
        </p>
      </div>
      <div className='sj-row-part borderRight last-updated-column'>
        <p className='truncate' style={{ marginTop: '12px' }}>
          {`Updated At: ${commonObject.parseDateLocal(
            updated_at
          )} by: ${update_admin}`}
        </p>
      </div>
      <div
        onClick={() => {
          props.openPdf(pdf_url);
        }}
        className='sj-row-part'
      >
        <p className='' style={{ marginTop: '12px' }}>
          {`Open PDF:`}
        </p>
        <img
          src={imageObject.pdf}
          style={{ marginTop: '12px', marginLeft: '5px' }}
          width={22}
          height={22}
        />
      </div>
      {localStorage.getItem('admin') == true ? (
        <>
          <div
            className='stk-row-part borderLeft'
            onClick={() => {
              let dataObject = {
                ...props.data,
                selectedUser: {
                  id: props.data.user_id,
                  username: props.data.username
                }
              };
              props.updateAction(dataObject);
            }}
          >
            <p
              className=''
              style={{ marginTop: '10px', width: '20', color: '#2193b0' }}
            >
              UPDATE
            </p>
          </div>
          <div
            className='stk-row-part borderLeft'
            onClick={() => {
              props.deleteAction(props.data);
            }}
          >
            <p
              className=''
              style={{ marginTop: '10px', width: '20', color: '#b92b27' }}
            >
              DELETE
            </p>
          </div>
        </>
      ) : null}
    </div>
  );
};

export default InvoiceRow;
