import React from 'react';
import '../style.css';
import imageObject from '../../../utils/images';
import { Modal, ListGroup } from 'react-bootstrap';
import { useState } from 'react';
import commonObject from '../../../utils/common.js';

const SuratJalanKeluarRow = props => {
  let {
    id,
    job_order,
    updated_at,
    updated_ats,
    pdf_urls,
    ids,
    update_admin
  } = props.data;
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <div className='sj-row' key={id}>
      <div className='sj-row-part borderRight job-order-column'>
        <p className='truncate' style={{ marginTop: '10px' }}>
          {`JOB ORDER:${job_order}`}
        </p>
      </div>
      <div className='sj-row-part borderRight last-updated-column'>
        <p className='' style={{ marginTop: '10px' }}>
          {`Updated At: ${commonObject.parseDateLocal(
            updated_at
          )} by: ${update_admin}`}
        </p>
      </div>
      <div
        onClick={() => {
          handleShow();
        }}
        className='sj-row-part'
      >
        <p className='' style={{ marginTop: '10px' }}>
          {`SHOW PDF LIST:`}
        </p>
        <img
          src={imageObject.pdf}
          style={{ marginTop: '10px', marginLeft: '5px' }}
          width={22}
          height={22}
        />
      </div>
      {localStorage.getItem('admin') == true ? (
        <>
          <div
            className='stk-row-part borderLeft'
            onClick={() => {
              props.deleteAction(props.data);
            }}
          >
            <p
              className=''
              style={{ marginTop: '10px', width: '20', color: '#b92b27' }}
            >
              DELETE
            </p>
          </div>
        </>
      ) : null}
      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>PDF LIST</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ListGroup>
            {updated_ats.map((updated_at, index) => {
              console.log(updated_at);
              return (
                <ListGroup.Item key={index}>
                  <div className='list-group-item-container'>
                    <div
                      style={{
                        display: 'flex'
                      }}
                      onClick={() => {
                        props.openPdf(pdf_urls[index]);
                      }}
                    >
                      <p>
                        {`created at: ${commonObject.parseHardDate(
                          updated_at
                        )} by ${update_admin}`}
                      </p>
                      <img
                        src={imageObject.pdf}
                        style={{ margin: '10px' }}
                        width={22}
                        height={22}
                      />
                    </div>
                    {localStorage.getItem('admin') == true ? (
                      <div
                        style={{
                          display: 'flex',
                          marginLeft: '20px'
                        }}
                        onClick={() => {
                          props.deleteActionSingle(ids[index]);
                        }}
                      >
                        <p
                          className=''
                          style={{
                            marginTop: '5px',
                            width: '20',
                            color: '#b92b27'
                          }}
                        >
                          DELETE
                        </p>
                      </div>
                    ) : null}
                  </div>
                </ListGroup.Item>
              );
            })}
          </ListGroup>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default SuratJalanKeluarRow;
