import React from 'react';
import '../style.css';

const UploadSelect = props => {
  let { selected, action } = props;

  return (
    <div className='upload-s-container'>
      <p className='matt'>single upload</p>
      <div
        className='circle ml-2'
        onClick={() => {
          action(1);
        }}
      >
        <div
          className={selected == 1 ? 'inner-circle selected-c' : 'inner-circle'}
        ></div>
      </div>
      <p className='matt ml-4'>batch upload</p>
      <div
        className='circle ml-2'
        onClick={() => {
          action(2);
        }}
      >
        <div
          className={selected == 2 ? 'inner-circle selected-c' : 'inner-circle'}
        ></div>
      </div>
    </div>
  );
};

export default UploadSelect;
