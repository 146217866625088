import React, { Component } from 'react';
import { Col, Image } from 'react-bootstrap';
import imageObject from '../../../utils/images';
import '../style.css'

const ServiceCard = (props) => {
  return ( 
    <Col md={4} sm={4} xs={4} className={props.key == 1 ? 'paderino' : 'no-pad'}>
      <div className={props.picked === props.service.id ? 'manage-select choice-attribute' : 'choice-attribute'} onClick={() => {
        props.handlePicked(props.service.id)
      }}>
        <Image
          className={"choice-image"}
          src={props.service.image}/>
        <p className='choice-text text-center'>{props.service.title}</p>
      </div>
    </Col>
  );
}
 
export default ServiceCard;