export default {
  aboutUsTitle: {
    EN: 'About Us',
    ID: 'Tentang Kami'
  },
  visionTitle: {
    EN: 'Our Vision',
    ID: 'Visi Kami'
  },
  missionTitle: {
    EN: 'Our Mission',
    ID: 'Misi Kami'
  },
  aboutUs1: {
    EN:
      'PT. Adhirajasa Trans Sejahtera, also known as ATS, is a third-party logistics (3PL) company established in 2020 in Jakarta, Indonesia. We provide services in freight forwarding, warehouse storage & fulfillment, and goods shipping throughout Indonesia.',
    ID:
      'PT. Adhirajasa Trans Sejahtera, dikenal juga sebagai ATS, adalah perusahaan logistik yang berdiri pada tahun 2020 berlokasi di Jakarta, Indonesia. Kami menyediakan layanan berupa penyewaan gudang umum dan pengiriman barang dalam cakupan seluruh wilayah Indonesia.'
  },
  aboutUs2: {
    EN:
      'We believe that efficiency and effectiveness are one of the main keys for a healthy business. There- fore, ATS is not only a third-party logistics company, but we aim to be a reliable partner for expanding your business.',
    ID:
      'Kami percaya bahwa efisiensi dan efektifitas adalah salah satu kunci utama untuk bisnis yang sehat. Karena itu, ATS hadir bukan hanya sebagai perusahaan logistik tapi juga partner Anda dalam mengembangkan bisnis.'
  },
  vision: {
    EN:
      '"To help companies in Indonesia expand business by implementing an efficient and cost-effective strategy in warehouse fulfillment & shipping services"',
    ID:
      '"Membantu mengembangkan bisnis perusahaan di Indonesia dengan menerapkan strategi yang efisien dan hemat biaya dalam layanan gudang umum & pengiriman barang"'
  },
  mission: {
    EN: [
      'To ensure sustainable and profitable growth that maximizes shareholder value.',
      'To always prioritize customers’ satisfaction by delivering effective and efficient services.',
      'Optimizing human resources for generating the best output.'
    ],
    ID: [
      'To ensure sustainable and profit- able growth that maximizes share- holder value.',
      'Selalu memprioritaskan kepuasan konsumen dengan memberikan layanan yang efektif, efisien, dan solutif.',
      'Optimalisasi sumber daya manusia untuk hasil yang maksimal.'
    ]
  },
  dataSectionTitle: {
    EN: 'OUR STATISTICS',
    ID: 'STATISTIK KAMI'
  },
  dataSubtitleHeading: {
    EN: 'Help us grow together',
    ID: 'Bantu kami tumbuh bersama'
  },
  dataSectionSubtitle: {
    EN:
      'We pride ourselves on delivering the best service for every logistical step that we do, while striving to improve for the more to come',
    ID:
      'Kami bangga dapat memberikan layanan terbaik untuk setiap langkah logistik yang kami lakukan, dan kami berusaha untuk meningkatkan layanan lebih lagi untuk yang akan datang'
  },
  landsizeDescription: {
    EN:
      'Our storage system is world class, We are constantly expanding space to better accamodate our customers needs',
    ID:
      'Sistem penyimpanan kami berkelas dunia, Kami terus memperluas ruang untuk mengakomodasi kebutuhan pelanggan kami dengan lebih baik'
  },
  containersDescription: {
    EN:
      'Every logistical step matters to us, ensuring that our quality improves and scales with every new container we ship.',
    ID:
      'Setiap langkah logistik penting bagi kami, memastikan bahwa kualitas kami terus meningkat dengan setiap kontainer baru yang kami kirim.'
  }
};
