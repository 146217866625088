import React from 'react';
import { Link } from 'react-router-dom';
import { Container, Navbar, Nav, NavDropdown, FormControl, Form, Button } from 'react-bootstrap';
import imageObject from '../../utils/images';
import router from 'react-router-dom';
import MyContext from '../../middleware/context';
import './style.css';

const Navigation = props => {

  const { routes } = props;

  return (
    <Navbar expand='sm' bg='light' variant='light' fixed='top'>
      <Container>
        <Navbar.Brand>
          <img
            src={imageObject.mainLogo}
            width="184"
            height="60"
            className="d-inline-block align-top"
            alt="React Bootstrap logo"
          />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className='ml-auto'>
            <MyContext.Consumer>
              {(context) => (
                <NavDropdown title={`Language: ${context.state.currentLanguage}`} id="basic-nav-dropdown">
                  <NavDropdown.Item onClick={() => {
                    context.changeLanguage("ENGLISH")
                  }}>English</NavDropdown.Item>
                  <NavDropdown.Item onClick={() => {
                    context.changeLanguage("BAHASA")
                  }}>Bahasa</NavDropdown.Item>
                </NavDropdown>
              )}
            </MyContext.Consumer>
            {routes
              .filter(route => route.isNavbar)
              .map((route, key) => (
                <Nav.Link key={key} >
                  <Link className={"active"} to={route.path}>{route.title}</Link>
                </Nav.Link>
              ))}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default Navigation;
