import React, { useState } from 'react';
import { Form, Modal, ListGroup, FormControl, Button } from 'react-bootstrap';
import Paginator from '../../../components/Pagination';
import '../style.css';

const CreateInput = props => {
  const {
    type,
    placeholder,
    name,
    option,
    valueChange,
    disabled,
    totalPages,
    page,
    action,
    value: tempVal,
    searchPlaceHolder,
    searchName,
    searchValueChange,
    searchValue,
    searchAction
  } = props;

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handlePageChange = page => {
    action(page);
  };

  const renderForm = (type, placeholder, name, option) => {
    if (type == 'form') {
      return (
        <Form.Control
          className={'ml-2 c-f-select'}
          as='select'
          name={name}
          onChange={valueChange}
        >
          {option.map((object, index) => {
            return (
              <option
                key={index}
                selected={tempVal == object.value ? true : false}
                value={object.value}
              >
                {object.text}
              </option>
            );
          })}
        </Form.Control>
      );
    } else if (type == 'user') {
      return (
        <>
          <Form.Control
            className={'ml-2 c-f-select'}
            type='text'
            placeholder={placeholder}
            value={
              props.value?.username != undefined ? props.value?.username : ''
            }
            name={name}
            readOnly
            onClick={() => {
              handleShow();
            }}
            onChange={valueChange}
          />
          <Modal show={show} centered onHide={handleClose}>
            <Modal.Header closeButton>
              <Modal.Title>Select A User</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form inline className='mb-3'>
                <FormControl
                  type='text'
                  placeholder={searchPlaceHolder}
                  value={searchValue}
                  name={searchName}
                  onChange={searchValueChange}
                  className='mr-sm-2'
                />
                <Button onClick={searchAction} variant='outline-success'>
                  Search
                </Button>
              </Form>
              <>
                {option.map((userObject, index) => {
                  return (
                    <ListGroup.Item
                      key={index}
                      onClick={() => {
                        let e = {
                          target: {
                            name,
                            value: userObject
                          }
                        };
                        valueChange(e);
                        handleClose();
                      }}
                    >
                      <p className='matt' style={{ margin: '0px' }}>
                        {userObject.username}
                      </p>
                    </ListGroup.Item>
                  );
                })}
                {totalPages != null ? (
                  <div style={{ margin: '0 auto', width: '40%' }}>
                    <Paginator
                      totalPages={totalPages}
                      page={page}
                      action={handlePageChange}
                    />
                  </div>
                ) : null}
              </>
            </Modal.Body>
          </Modal>
        </>
      );
    } else if (type == 'text') {
      return (
        <Form.Control
          className={'ml-2 c-f-select'}
          type='text'
          disabled={disabled == undefined ? false : disabled}
          placeholder={placeholder}
          value={props.value}
          name={name}
          onChange={valueChange}
        />
      );
    } else if (type == 'number') {
      return (
        <Form.Control
          className={'ml-2 c-f-select'}
          type='number'
          disabled={disabled == undefined ? false : disabled}
          placeholder={placeholder}
          value={props.value}
          name={name}
          onChange={valueChange}
        />
      );
    }
  };

  return (
    <>
      <div className='c-i-container'>
        <div className='lhs-c-i'>
          <p className='c-text j-r'>{props.text}</p>
        </div>
        <div className='rhs-c-i'>
          {renderForm(type, placeholder, name, option)}
        </div>
      </div>
    </>
  );
};

export default CreateInput;
