import dateFormat from 'dateformat';

let commonObject = {
  parseDateLocal: dateString => {
    let date = new Date(dateString);
    let adjustedDate = date.setHours(date.getHours() + 7);
    return dateFormat(adjustedDate, 'hh:MM TT dd/mmm/yyyy');
  },

  parseHardDate: dateString => {
    let date = new Date(dateString);
    let adjustedDate = date.setHours(date.getHours() + 14);
    return dateFormat(adjustedDate, 'hh:MM TT dd/mmm/yyyy');
  },

  parseMonth: month => {
    let monthArr = {
      1: 'January',
      2: 'February',
      3: 'March',
      4: 'April',
      5: 'May',
      6: 'June',
      7: 'July',
      8: 'August',
      9: 'September',
      10: 'October',
      11: 'November',
      12: 'December'
    };

    return monthArr[month];
  },

  getDayTimeString: () => {
    const today = new Date();
    let curHr = today.getHours();

    if (curHr < 12) {
      return 'Good Morning';
    } else if (curHr < 18) {
      return 'Good Afternoon';
    } else {
      return 'Good Evening';
    }
  }
};

export default commonObject;
