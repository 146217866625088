export default {
  contactTitle: {
    EN: 'Contact Us',
    ID: 'Hubungi Kami'
  },
  subheading: {
    EN: 'Get in Touch',
    ID: 'Berhubungan dengan kami'
  },
  contactSubtext1: {
    EN: 'PT. Adhirajasa Trans Sejahtera, also known as ATS, is a third-party logistics (3PL) company established in 2020 in Jakarta, Indonesia. We provide services in freight forwarding, warehouse storage & fulfillment, and goods shipping throughout Indonesia.',
    ID: 'PT. Adhirajasa Trans Sejahtera, dikenal juga sebagai ATS, adalah perusahaan logistik yang berdiri pada tahun 2020 berlokasi di Jakarta, Indonesia. Kami menyediakan layanan berupa penyewaan gudang umum dan pengiriman barang dalam cakupan seluruh wilayah Indonesia.'
  },
  contactSubtext2: {
    EN: 'We believe that efficiency and effectiveness are one of the main keys for a healthy business. There- fore, ATS is not only a third-party logistics company, but we aim to be a reliable partner for expanding your business.',
    ID: 'Kami percaya bahwa efisiensi dan efektifitas adalah salah satu kunci utama untuk bisnis yang sehat. Karena itu, ATS hadir bukan hanya sebagai perusahaan logistik tapi juga partner Anda dalam mengembangkan bisnis.'
  },
  selectText: {
    EN: 'Please select a service you wish to enquire',
    ID: 'Pilih layanan yang ingin Anda tanyakan'
  }
}