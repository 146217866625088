import React from 'react';
import './style.css';
import DatePicker from 'react-datepicker';
import Switch from 'react-switch';

import 'react-datepicker/dist/react-datepicker.css';

const FilterDate = props => {
  let { action, checked, start_date, end_date, dateChanged } = props;

  return (
    <div className='filter-date-container'>
      <div className='date-switch-container'>
        <p className='filter-text'>Filter Date</p>
        <Switch
          className='level-switch ml-5'
          onChange={action}
          checked={checked}
          height={21}
          width={47}
        />
      </div>
      <div className='date-picker-section-container'>
        <div className='date-picker-container'>
          <p className='level-date-picker filter-text'>Start Date:</p>
          <DatePicker
            selected={start_date}
            onChange={date => {
              dateChanged('start_date', date);
            }}
          />
        </div>
        <div className='date-picker-container ml-2'>
          <p className='level-date-picker filter-text'>End Date:</p>
          <DatePicker
            selected={end_date}
            onChange={date => {
              dateChanged('end_date', date);
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default FilterDate;
