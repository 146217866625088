import React from 'react';
import { Row, Col } from 'react-bootstrap';

import '../style.css';

const ContactInfo = () => {
  const openWA = () => {
    const win = window.open('https://wa.me/6281265651212', '_blank');
    win.focus();
  };

  return (
    <div className='bg-green py-4 px-3 mb-3'>
      <p className='font-bold color-white'>How to find us</p>
      <Row>
        <Col xs={2} sm={2} md={1} lg={3}>
          <div className='icon location-icon' />
        </Col>
        <Col xs={10} sm={10} m={11} lg={9}>
          <p className='text-left color-white'>
            Gudang Cengkareng: Pergudangan Kedaung, Jl. Peternakan I blok B4 RT
            006 RW 001, Kedaung Kali Angke, Cengkareng, West Jakarta, 11710
          </p>
          <p className='text-left color-white'>
            Gudang Ancol: Jl Ancol barat 6 no.3, ancol pademangan, jakarta utara
          </p>
          <p className='text-left color-white'>
            Gudang Puit: JL Pluit raya kav. 70, Penjaringan, Jakarta Utara
          </p>
        </Col>
      </Row>
      <Row>
        <Col xs={2} sm={2} md={1} lg={3}>
          <div className='icon whatsapp-icon' onClick={openWA} />
        </Col>
        <Col xs={10} sm={10} m={11} lg={9}>
          <p className='text-left color-white' onClick={openWA}>
            +62 81265651212
          </p>
        </Col>
      </Row>
      <Row>
        <Col xs={2} sm={2} md={1} lg={3}>
          <div className='icon phone-icon' onClick={openWA} />
        </Col>
        <Col xs={10} sm={10} m={11} lg={9}>
          <p className='text-left color-white'>+(021) 22301212</p>
        </Col>
      </Row>
      <Row>
        <Col xs={2} sm={2} md={1} lg={3}>
          <div className='icon mail-icon' />
        </Col>
        <Col xs={10} sm={10} m={11} lg={9}>
          <p className='text-left color-white'>
            adhirajasatranssejahtera@gmail.com
          </p>
        </Col>
      </Row>
    </div>
  );
};

export default ContactInfo;
